import React, { useState } from 'react';
import ChatMessage from './ChatMessage';

function ChatPanel({ messages, users }) {
  const [newMessage, setNewMessage] = useState('');

  const getUserById = (userId) => {
    return users.find(user => user.id === userId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    console.log('New message:', newMessage);
    setNewMessage('');
  };

  return (
    <div className="chat-panel">
      <h2 className="chat-panel-title">Chats</h2>
      <p className="chat-panel-subtitle">Use Philips TV app to type messages</p>
      
      <div className="chat-panel-messages">
        {messages.map((message) => (
          <ChatMessage 
            key={message.id} 
            message={message} 
            user={getUserById(message.userId)} 
          />
        ))}
      </div>

      <form onSubmit={handleSubmit} className="chat-panel-form">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          className="chat-panel-input"
        />
      </form>
    </div>
  );
}

export default ChatPanel;
