// import React from 'react';

// function ChannelInfo() {
//   return <h1>Channel Info Page</h1>;
// }

// export default ChannelInfo;



// import React, { useEffect, useState } from "react";
// import { useNavigate , useLocation  } from 'react-router-dom';
// import rawChannelData from "./ActuallchannelData.json";
// import './ProgramInfo.css';

// const ProgrammeInfo = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//     const [TVCall, setTVCall] = useState("");
//     const program = location.state?.program || {};
//     console.log("Program info channel",program)

//   return (
//     <div className="container">
//       {/* Header */}
//       <header className="programme-header" onClick={() =>  navigate('/event/tvguid')}>Programme Info</header>

//       {/* Channel Info Section */}
//       <div className="channel-info">
//         <div className="channel-info_new">
//           <div className="channel-logo_new">TV</div>
//           <div>
//             <div className="channel-text_new">BBC ONE</div>
//             <div className="channel-number_new">806</div>
//           </div>
//         </div>

//         <div className="show-image">
//           <img
//             src="https://cdn.pixabay.com/photo/2018/03/26/20/21/some-people-dont-3263908_1280.jpg"
//             alt="Show"
//             width="100%"
//             height="100%"
//           />
//         </div>
//         <div className="show-time">
//           Thursday, 13 APR 2022<br />15:30-16:45
//         </div>
//         <button className="btn btn-light w-100 py-3 fw-semibold">
//           Create a Watch Room
//         </button>
//       </div>

//       {/* Programme Details Section */}
//       <div className="programme-details">
//         <div className="programme-title">First Dates Hotel</div>
//         <div className="programme-meta">
//           <h3>
//             65 mins | Entertainment | Suitable only for 15 years and over | 16:9
//           </h3>
//         </div>
//         <div className="programme-description">
//           <h2>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit. Non ducimus
//             corporis minus harum accusamus doloremque reiciendis vero error rerum,
//             facilis nemo similique repudiandae maxime? Culpa blanditiis at
//             voluptates eum vel. Lorem ipsum dolor sit amet consectetur adipisicing
//             elit. Non ducimus corporis minus harum accusamus doloremque reiciendis
//             vero error rerum, facilis nemo similique repudiandae maxime? Culpa
//             blanditiis at voluptates eum vel.
//             {TVCall}
//           </h2>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgrammeInfo;


// dynamic data

// import React, { useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import rawChannelData from "./ActuallchannelData.json";
// import "./ProgramInfo.css";

// const ProgrammeInfo = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const program = location.state?.program || {};

//   // Find the matching object from JSON
//   const programDetails = rawChannelData.find((item) => item._id === program.Id);

//   useEffect(() => {
//     if (programDetails) {
//       console.log("Program Details:", programDetails);
//     } else {
//       console.log("No matching program found.");
//     }
//   }, [programDetails]);

//   return (
//     <div className="container">
//       {/* Header */}
//       <header
//         className="programme-header"
//         onClick={() => navigate("/event/tvguid")}
//       >
//         Programme Info
//       </header>

//       {/* Display Program Details */}
//       {programDetails ? (
//         <>
//           <div className="channel-info">
//             <div className="channel-info_new">
//               <div className="channel-logo_new">TV</div>
//               <div>
//                 <div className="channel-text_new">
//                   {programDetails.display_name}
//                 </div>
//                 <div className="channel-number_new">
//                   {programDetails.display_number}
//                 </div>
//               </div>
//             </div>

//             {/* Image Section */}
//             <div className="show-image">
//               <img
//                 src={
//                   programDetails.imageUrl ||
//                   "https://cdn.pixabay.com/photo/2018/03/26/20/21/some-people-dont-3263908_1280.jpg"
//                 }
//                 alt={programDetails.title || "Program Image"}
//                 width="100%"
//                 height="100%"
//               />
//             </div>

//             <div className="show-time">
//               Start Time:{" "}
//               {new Date(
//                 parseInt(programDetails.start_time_utc_millis)
//               ).toLocaleString()}
//               <br />
//               End Time:{" "}
//               {new Date(
//                 parseInt(programDetails.end_time_utc_millis)
//               ).toLocaleString()}
//             </div>
//             <button className="btn btn-light w-100 py-3 fw-semibold">
//               Create a Watch Room
//             </button>
//           </div>

//           {/* Programme Details Section */}
//           <div className="programme-details">
//             <div className="programme-title">{programDetails.title}</div>
//             <div className="programme-meta">
//               <h3>{programDetails.broadcast_genre}</h3>
//             </div>
//             <div className="programme-description">
//               <h2>
//                 {programDetails.long_description || "No description available."}
//               </h2>
//             </div>
//           </div>
//         </>
//       ) : (
//         <p>No program details found.</p>
//       )}
//     </div>
//   );
// };

// export default ProgrammeInfo;



// create event

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import rawChannelData from "./ActuallchannelData.json";
import "./ProgramInfo.css";

const ProgrammeInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const program = location.state?.program || {};

  // Find the matching object from JSON
  const programDetails = rawChannelData.find((item) => item._id === program.Id);

  useEffect(() => {
    // Set the background color to black when the component loads
    document.body.style.backgroundColor = "black";

    // Cleanup function to reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  useEffect(() => {
    if (programDetails) {
      console.log("Program Details:", programDetails);
    } else {
      console.log("No matching program found.");
    }
  }, [programDetails]);

  const handleCreateWatchRoom = async () => {
    if (!programDetails) {
      alert("Program details not found!");
      return;
    }

    const payload = {
      roomName: "DefultNewRooms2025",
      hostUserId: "unique-host-user-id",
      showTime: [
        // {
        //   service_id: programDetails.service_id,
        //   on_id: programDetails.on_id,
        //   ts_id: programDetails.ts_id,
        //   channel_id: programDetails.channel_id,
        // },
        // {"service_id":"4165","on_id":"9018","ts_id":"4165","channel_id":"17"},
        programDetails,
      ],
      maxParticipants: 10,
      participants: [
        { userId: "unique-user-id-1" },
        { userId: "unique-user-id-2" },
      ],
      currentTimestamp: 120,
      roomConversion: {
        messagesSent: 50,
      },
      qrUrl: "https://example.com/qr-code",
      premimun: {
        paid: false,
        status: false,
      },
    };

    try {
      const response = await fetch(
        "https://watchparty.tpvai.com/b/api/createWatchRoom",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Watch room created successfully:", data);
        navigate("/event");
      } else {
        console.error("Failed to create watch room:", response.statusText);
        alert("Failed to create watch room. Please try again.");
      }
    } catch (error) {
      console.error("Error creating watch room:", error);
      alert("An error occurred while creating the watch room.");
    }
  };

  return (
    <div className="container-info">
      {/* Header */}
      <header
        className="programme-header"
        onClick={() => navigate("/event/tvguid")}
      >
        Programme Info
      </header>

      {/* Display Program Details */}
      {programDetails ? (
        < >
          <div className="channel-info">
            <div className="channel-info_new">
              <div className="channel-logo_new">TV</div>
              <div>
                <div className="channel-text_new">
                  {programDetails.display_name}
                </div>
                <div className="channel-number_new">
                  {programDetails.display_number}
                </div>
              </div>
            </div>

            {/* Image Section */}
            <div className="show-image">
              <img
                src={
                  programDetails.imageUrl ||
                  "https://cdn.pixabay.com/photo/2018/03/26/20/21/some-people-dont-3263908_1280.jpg"
                }
                alt={programDetails.title || "Program Image"}
                width="100%"
                height="100%"
              />
            </div>

            <div className="show-time">
              Start Time:{" "}
              {new Date(
                parseInt(programDetails.start_time_utc_millis)
              ).toLocaleString()}
              <br />
              End Time:{" "}
              {new Date(
                parseInt(programDetails.end_time_utc_millis)
              ).toLocaleString()}
            </div>
            <button
              className="btn btn-light w-100 py-3 fw-semibold"
              onClick={handleCreateWatchRoom}
            >
              Create a Watch Room
            </button>
          </div>

          {/* Programme Details Section */}
          <div className="programme-details">
            <div className="programme-title">{programDetails.title}</div>
            <div className="programme-meta">
              <h3>{programDetails.broadcast_genre}</h3>
            </div>
            <div className="programme-description">
              <h2>
                {programDetails.long_description || "No description available."}
              </h2>
            </div>
          </div>
        </>
      ) : (
        <p>No program details found.</p>
      )}
    </div>
  );
};

export default ProgrammeInfo;
