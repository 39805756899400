import React, { useState } from 'react';
import axios from 'axios';

const CreateEvent = () => {
    const [eventName, setEventName] = useState('');
    const [eventTime, setEventTime] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Convert the selected time to UTC
        const localDate = new Date(eventTime);  // The value from the datetime-local input is in local time
        const utcDate = new Date(localDate.toISOString());  // Convert to UTC using toISOString

        // The resulting utcDate will be in ISO 8601 format with 'Z' at the end (indicating UTC)
        const formattedTime = utcDate.toISOString(); // This will have 'Z' to indicate UTC

        const eventData = {
            name: eventName,
            time: formattedTime,  // Sending the time in UTC format
        };

        try {
            // Send POST request to the backend to create an event
            const response = await axios.post('http://localhost:5000/api/events', eventData);

            // Handle success
            console.log('Event created:', response.data);
            alert('Event created successfully!');
        } catch (error) {
            // Handle error
            console.error('Error creating event:', error);
            alert('Error creating event');
        }
    };

    return (
        <div>
            <h2>Create Event</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="eventName">Event Name:</label>
                    <input
                        type="text"
                        id="eventName"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="eventTime">Event Time:</label>
                    <input
                        type="datetime-local"
                        id="eventTime"
                        value={eventTime}
                        onChange={(e) => setEventTime(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Create Event</button>
            </form>
        </div>
    );
};

export default CreateEvent;
