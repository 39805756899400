import React from 'react';
import { Circle } from 'lucide-react';
import UserAvatar from './UserAvatar';

function GroupList({ users }) {
  return (
    <div className="group-list">
      <h2 className="group-list-title">Group</h2>
      <div className="group-list-items">
        {users.map((user) => (
          <div key={user.id} className="group-list-item">
            <UserAvatar user={user} />
            <span className="group-list-name">{user.name} {user.isHost && '(Host)'}</span>
            <Circle 
              size={8} 
              className={`group-list-status ${user.isOnline ? 'online' : 'offline'}`} 
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default GroupList;
