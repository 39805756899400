// import React from 'react';
// import { Link } from 'react-router-dom';

// function Event() {
//   return (
//     <div>
//       <h1>Event Page</h1>
//       <ul>
//         <li><Link to="/event/tvguid">Tvguid</Link></li>
//         <li><Link to="/event/tvguid/channelinfo">Channel Info</Link></li>
//         <li><Link to="/event/rooms">Rooms</Link></li>
//       </ul>
//     </div>
//   );
// }

// export default Event;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CirclePlus } from 'lucide-react';
import QRCode from "qrcode";
import "./WatchRoom.css";

const WatchRoom = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [rooms, setRooms] = useState([]);
  const [usernameid, setUsernameid] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Manage background image state
  const [dateTime, setDateTime] = useState("");
  const roomRefs = useRef([]);

  const navigate = useNavigate()
  // useEffect(() => {
  //   // Generate QR Code
  //   QRCode.toDataURL("https://example.com")
  //     .then((url) => setQrCodeUrl(url))
  //     .catch((err) => console.error("Error generating QR code", err));
  // }, []);

  const formatDateTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const day = now.toLocaleString("en-US", { weekday: "short" });
    const date = now.getDate().toString().padStart(2, "0");
    const month = now.toLocaleString("en-US", { month: "short" });
    const year = now.getFullYear();

    return `${hours}:${minutes} ${day} ${date} ${month} ${year}`;
  };


  useEffect(() => {
    // Generate QR Code
    QRCode.toDataURL("https://example.com")
      .then((url) => setQrCodeUrl(url))
      .catch((err) => console.error("Error generating QR code", err));

    // Fetch watch rooms data
    fetch("https://watchparty.tpvai.com/b/api/getWatchRooms")
      .then((response) => response.json())
      .then((data) => {
        setRooms(data); // Store the fetched rooms data in state
      })
      .catch((error) => console.error("Error fetching rooms:", error));
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = '../../public/images/view-3d-cinema-theatre-room.jpg';
    img.onload = () => {
      setIsImageLoaded(true);
    };
    const storedEmail = localStorage.getItem('userId');
    const username = storedEmail.split("@")[0];
    setUsernameid(username);
  }, []);

  useEffect(() => {
    // Set the initial dateTime
    setDateTime(formatDateTime());

    // Update the time every second
    const interval = setInterval(() => {
      setDateTime(formatDateTime());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleKeyDown = (event, index,roomId) => {
    if (event.key === "ArrowRight") {
      roomRefs.current[index + 1]?.focus();
    } else if (event.key === "ArrowLeft") {
      roomRefs.current[index - 1]?.focus();
    } else if (event.key === "Enter") {
      // alert(`Selected Room: Room ${index + 1}`);
      navigate("/event/rooms",{ state: { roomId } });
    }
  };


  const handleClick = () => {
    navigate("/event/tvguid");
  };

  return (
    // <div className="app">
    <div className={`app ${isImageLoaded ? "high-quality" : "low-quality"}`}>
      {/* Header Section */}
      <header className="header">
        <div className="logo-container">
          <div className="logo">
            <span>R</span>
          </div>
          
        </div>
      </header>

      {/* Sidebar */}
      <div className="sidebar-watch">
        <div className="sidebar-content-watch">
          {/* My Rooms Section */}
          <div className="sidebar-section-watch">
            <h3>My Rooms</h3>
            <button className="tv-guide-btn" onClick={handleClick}>
              <span className="menu-icon">≡</span>
              <span>TV Guide</span>
            </button>
            <p className="guide-text">
              Add a room from TV Guide via Options or Programme info
            </p>
          </div>

          {/* QR Code Section */}
          <div className="qr-section">
            <h3>Joined Rooms</h3>
            {qrCodeUrl ? (
              <img
                src={qrCodeUrl}
                alt="QR Code"
                style={{ width: "100px", height: "100px" }}
              />
            ) : (
              <p>Loading QR Code...</p>
            )}
            <p className="guide-text">Scan to join a room from invitation</p>
          </div>
        </div>
      </div>
      {/* <div className="main-content-grid">
        <div className="my-rooms">
          

{rooms.map((room, index) => {
  const showTime = room.showTime?.[0] || {};
  const title = showTime.title || "806 First Dates Hotel";

  // Convert start and end times to Date objects
  const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
  const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

  // Format date and time
  const formattedTimeRange = `${[
    String(startTime.getDate()).padStart(2, "0"),
    String(startTime.getMonth() + 1).padStart(2, "0"),
    startTime.getFullYear(),
  ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

  // Format short date for display
  const formattedShortDate = startTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  return (
    <div className="room-container" key={room.id || index}>
      <div
        className="room-card my-room-card"
        tabIndex={0}
        ref={(el) => (roomRefs.current[index] = el)}
        onKeyDown={(event) => handleKeyDown(event, index, room.roomId)}
      >
        <p className="room-title">{title}</p>
        <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
      </div>
      <p className="short-date">{formattedShortDate || "13 Apr"}</p>
    </div>
  );
})}




          

          
        </div>

        <div className="joined-rooms">
           
{rooms.map((room, index) => {
  const showTime = room.showTime?.[0] || {};
  const title = showTime.title || "806 First Dates Hotel";

  // Convert start and end times to Date objects
  const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
  const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

  // Format date and time
  const formattedTimeRange = `${[
    String(startTime.getDate()).padStart(2, "0"),
    String(startTime.getMonth() + 1).padStart(2, "0"),
    startTime.getFullYear(),
  ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

  // Format short date for display
  const formattedShortDate = startTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
  });

  const isExpired = room.isExpired; // Use room.isExpired from API response

  return (
    <div
      className={`room-container ${isExpired ? "disabled-room" : ""}`}
      key={room.id || index}
    >
      <div
        className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
        tabIndex={isExpired ? -1 : 0} // Prevent focus on expired rooms
        ref={(el) => (roomRefs.current[index] = el)}
        onKeyDown={(event) => !isExpired && handleKeyDown(event, index, room.roomId)}
        style={{ pointerEvents: isExpired ? "none" : "auto", opacity: isExpired ? 0.5 : 1 }}
      >
        <p className="room-title">{title}</p>
        <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
      </div>
      <p className="short-date">{formattedShortDate || "13 Apr"}</p>
    </div>
  );
})}


        </div>
      </div> */}

<div className="main-content-grid">
  {/* {rooms.length === 0 ? ( */}
  {0 === 0 ? (
    <div className="welcome-container">
  <div className="w-title">Welcome {usernameid}!</div>
  <p className="welcome-message">
    You may create a Watch room and invite your guests to join a watch session. Or simply join one.
  </p>
  <p className="welcome-requirements">
    A Philips TV with Watch Room feature and Internet are required.
  </p>
</div>

  ) : (
    <>
      <div className="my-rooms">
        {Array.from({ length: 3 }).map((_, index) => (
          <div className="room-container" key={index}>
            <div
              className="room-card my-room-card"
              tabIndex={0}
              ref={(el) => (roomRefs.current[index] = el)}
              onKeyDown={(event) => handleKeyDown(event, index)}
            >
              <p className="room-title">Room {index + 1}</p>
              <p className="room-time">13-04-2022 15:30 - 16:45</p>
            </div>
            <p className="short-date">13 Apr</p>
          </div>
        ))}
      </div>
      <div className="joined-rooms">
        {rooms.map((room, index) => {
          const showTime = room.showTime?.[0] || {};
          const title = showTime.title || "806 First Dates Hotel";

          const startTime = new Date(parseInt(showTime.start_time_utc_millis || "0"));
          const endTime = new Date(parseInt(showTime.end_time_utc_millis || "0"));

          const formattedTimeRange = `${[
            String(startTime.getDate()).padStart(2, "0"),
            String(startTime.getMonth() + 1).padStart(2, "0"),
            startTime.getFullYear(),
          ].join("-")} ${startTime.toTimeString().slice(0, 5)} - ${endTime.toTimeString().slice(0, 5)}`;

          const formattedShortDate = startTime.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
          });

          const isExpired = room.isExpired;

          return (
            <div
              className={`room-container ${isExpired ? "disabled-room" : ""}`}
              key={room.id || index}
            >
              <div
                className={`room-card my-room-card ${isExpired ? "disabled" : ""}`}
                tabIndex={isExpired ? -1 : 0}
                ref={(el) => (roomRefs.current[index] = el)}
                onKeyDown={(event) =>
                  !isExpired && handleKeyDown(event, index, room.roomId)
                }
                style={{
                  pointerEvents: isExpired ? "none" : "auto",
                  opacity: isExpired ? 0.5 : 1,
                }}
              >
                <p className="room-title">{title}</p>
                <p className="room-time">{formattedTimeRange || "13-04-2022 15:30 - 16:45"}</p>
              </div>
              <p className="short-date">{formattedShortDate || "13 Apr"}</p>
            </div>
          );
        })}
      </div>
    </>
  )}
</div>

      <div className="footer">
        <span ></span>
        <button style={{
          color: '#b3b3b3',
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          fontSize:'smaller'
        }}>
         {dateTime} <button class="plus-button"></button>
         OPTIONS
        </button>
      </div>
    </div>
  );
};

export default WatchRoom;
