import React, { useState, useEffect } from 'react';
import './AdBanner.css';

const AdBanner = () => {
  const [showAd, setShowAd] = useState(true);
  const [imageSrc, setImageSrc] = useState('');
  const adDisplayTime = 50000; // Time to show the ad (in milliseconds)
  const adHideTime = 10000; // Time to hide the ad (in milliseconds)
  const images = ['ad-banner1.jpg', 'ad-banner2.jpg', 'ad-banner3.jpg'];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  };

  useEffect(() => {
    let showTimeout, hideTimeout;

    if (showAd) {
      setImageSrc(getRandomImage());
      hideTimeout = setTimeout(() => {
        setShowAd(false);
      }, adDisplayTime);
    } else {
      showTimeout = setTimeout(() => {
        setShowAd(true);
      }, adHideTime);
    }

    // Clean up timeouts
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [showAd]);


  console.log("imageSrc",imageSrc)
  const handleClose = () => {
    setShowAd(false);
  };

  return (
    showAd && (
      <div className="ad-banner">
        {/* <img src={`https://watchparty.tpvai.com/b/uploads/${imageSrc}`} alt="Ad Banner" /> */}
        <img src={`https://watchparty.tpvai.com/b/uploads/add your banner.png`} alt="Ad Banner here" />

      </div>

      // <>
      // </>
    )
  );
};

export default AdBanner;
