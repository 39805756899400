// // import React, { useEffect} from "react";

// // const Page5 = ({ setCurrentAiPage, generatedImage, isLoading , sendMessage }) => {
// //   const handleBackClick = () => {
// //     setCurrentAiPage("main");
// //   };

// //   const handleGenerateClick = (generatedImage) => {
// //     console.log("i have data->",generatedImage)
// //     // alert("Stickers Sent->",generatedImage);
// //     sendMessage(generatedImage)
// //   };

// //   useEffect(() => {
// //     const handleKeyPress = (e) => {
// //       if (e.key === "2") {
// //         handleGenerateClick(generatedImage);
// //       } else if (e.key === "5") {
// //         handleBackClick();
// //       }
// //     };

// //     window.addEventListener("keydown", handleKeyPress);
// //     return () => {
// //       window.removeEventListener("keydown", handleKeyPress);
// //     };
// //   }, []);

// //   console.log("AI Image:-",generatedImage)

// //   return (
// //     <div style={{
// //       display: "flex",
// //       flexDirection: "column",
// //       alignItems: "center",
// //       marginTop: "20px",
// //       fontFamily: "Arial, sans-serif",
// //       color: "#333"
// //     }}>
// //       <div
// //         style={{
// //           position: "absolute",
// //           top: "10px",
// //           left: "10px",
// //           cursor: "pointer",
// //           fontSize: "14px",
// //           color: "#007bff",
// //           display: "flex",
// //           alignItems: "center",
// //           transition: "color 0.2s",
// //         }}
// //         onClick={handleBackClick}
// //       >
// //         Home
// //         <p style={{
// //           margin: "0",
// //           fontSize: "12px",
// //           textAlign: "center",
// //           marginLeft: "5px"
// //         }}>5</p>
// //       </div>

// //       <div style={{
// //         marginTop: "20px",
// //         width: "80%",
// //         textAlign: "center",
// //       }}>
// //         {isLoading ? (
// //           <div style={{ padding: "20px" }}>Generating image...</div>
// //         ) : (
// //           <img
// //             src={generatedImage || "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1"}
// //             alt="Generated"
// //             style={{
// //               width: "100%",
// //               height: "170px",
// //               objectFit: "cover",
// //               border: "2px solid #007bff",
// //               borderRadius: "8px",
// //               transition: "transform 0.3s ease, box-shadow 0.3s ease",
// //             }}
// //             onMouseOver={(e) => {
// //               e.target.style.transform = "scale(1.05)";
// //               e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
// //             }}
// //             onMouseOut={(e) => {
// //               e.target.style.transform = "scale(1)";
// //               e.target.style.boxShadow = "none";
// //             }}
// //           />
// //         )}
// //       </div>

// //       <div style={{ marginTop: "20px" }}>
// //         <button
// //           onClick={() => handleGenerateClick(generatedImage)}
// //           style={{
// //             padding: "10px 20px",
// //             border: "2px solid #007bff",
// //             borderRadius: "8px",
// //             fontSize: "14px",
// //             backgroundColor: "#007bff",
// //             color: "white",
// //             cursor: "pointer",
// //             transition: "background-color 0.3s, transform 0.2s",
// //           }}
// //           onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
// //           onMouseOut={(e) => e.target.style.backgroundColor = "#007bff"}
// //         >
// //           Send
// //         </button>
// //         <p style={{
// //           marginTop: "5px",
// //           fontSize: "12px",
// //           textAlign: "center",
// //           color: "#555",
// //         }}>2</p>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Page5;


// // key handling
// import React, { useEffect } from "react";

// const Page5 = ({ setCurrentAiPage, generatedImage, isLoading, sendMessage }) => {
//   const handleBackClick = () => {
//     setCurrentAiPage("main");
//   };

//   const handleGenerateClick = (generatedImage) => {
//     console.log("i have data->", generatedImage);
//     sendMessage(generatedImage); // This triggers your send action
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "2") {
//         handleGenerateClick(generatedImage); // Triggering on `2`
//       } else if (e.key === "5") {
//         handleBackClick(); // Triggering on `5`
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [generatedImage]); // Adding `generatedImage` as a dependency

//   console.log("AI Image:-", generatedImage);

//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         marginTop: "20px",
//         fontFamily: "Arial, sans-serif",
//         color: "#333",
//         padding: "0px 23px"
//       }}
//     >
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//           transition: "color 0.2s",
//         }}
//         onClick={handleBackClick}
//       >
//         Home
//         <p
//           style={{
//             margin: "0",
//             fontSize: "12px",
//             textAlign: "center",
//             marginLeft: "5px",
//           }}
//         >
//           5
//         </p>
//       </div>

//       <div
//         style={{
//           marginTop: "20px",
//           width: "80%",
//           textAlign: "center",
//         }}
//       >
//         {isLoading ? (
//           <div style={{ padding: "20px" }}>Generating image...</div>
//         ) : (
//           <img
//             src={
//               generatedImage ||
//               "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1"
//             }
//             alt="Generated"
//             style={{
//               width: "100%",
//               height: "111px",
//               objectFit: "cover",
//               border: "2px solid #007bff",
//               borderRadius: "8px",
//               transition: "transform 0.3s ease, box-shadow 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.transform = "scale(1.05)";
//               e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.transform = "scale(1)";
//               e.target.style.boxShadow = "none";
//             }}
//           />
//         )}
//       </div>

//       <div style={{ marginTop: "20px" }}>
//         <button
//           onClick={() => handleGenerateClick(generatedImage)}
//           style={{
//             padding: "10px 20px",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "14px",
//             backgroundColor: "#007bff",
//             color: "white",
//             cursor: "pointer",
//             transition: "background-color 0.3s, transform 0.2s",
//           }}
//           onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
//           onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
//         >
//           Send
//         </button>
//         <p
//           style={{
//             marginTop: "5px",
//             fontSize: "12px",
//             textAlign: "center",
//             color: "#555",
//           }}
//         >
//           2
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Page5;


// new ui changes
// import React, { useEffect } from "react";

// const Page5 = ({ setCurrentAiPage, generatedImage, isLoading, sendMessage }) => {
//   const handleBackClick = () => {
//     setCurrentAiPage("main");
//   };

//   const handleGenerateClick = (generatedImage) => {
//     console.log("i have data->", generatedImage);
//     sendMessage(generatedImage); // This triggers your send action
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "2") {
//         handleGenerateClick(generatedImage); // Triggering on `2`
//       } else if (e.key === "5") {
//         handleBackClick(); // Triggering on `5`
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [generatedImage]);

//   return (
//     <div style={{
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       fontFamily: "Arial, sans-serif",
//       color: "#333",
//       padding:'21px',
//     }}>
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//         }}
//         onClick={handleBackClick}
//       >
//         Home
//         <p style={{
//           margin: "0 0 0 5px",
//           fontSize: "12px",
//           textAlign: "center"
//         }}>
//           5
//         </p>
//       </div>

//       <div style={{ marginTop: "10px", width: "100%", textAlign: "center" }}>
//         {isLoading ? (
//           <div>Generating image...</div>
//         ) : (
//           <img
//             src={
//               generatedImage ||
//               "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/ba/29/5c/img-worlds-of-adventure.jpg?w=1200&h=1200&s=1"
//             }
//             alt="Generated"
//             style={{
//               width: "100%",
//               height: "100px",
//               objectFit: "cover",
//               border: "2px solid #007bff",
//               borderRadius: "8px",
//               transition: "transform 0.3s ease, box-shadow 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.transform = "scale(1.05)";
//               e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.transform = "scale(1)";
//               e.target.style.boxShadow = "none";
//             }}
//           />
//         )}
//       </div>

//       <div style={{ marginTop: "10px" }}>
//         <button
//           onClick={() => handleGenerateClick(generatedImage)}
//           style={{
//             padding: "8px 16px",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "14px",
//             backgroundColor: "#007bff",
//             color: "white",
//             cursor: "pointer",
//             transition: "background-color 0.3s, transform 0.2s",
//           }}
//           onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
//           onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
//         >
//           Send
//         </button>
//         <p style={{
//           margin: "5px 0 0",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#555"
//         }}>
//           2
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Page5;


// laoder
// import React, { useEffect } from "react";

// const Page5 = ({ setCurrentAiPage, generatedImage, isLoading, sendMessage,currentAiPage }) => {
//   const handleBackClick = () => {
//     // setCurrentAiPage("main");
//     setCurrentAiPage(1);
//   };

//   const handleGenerateClick = (generatedImage) => {
//     console.log("i have data->", generatedImage);
//     sendMessage(generatedImage); // This triggers your send action
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "2") {
//         handleGenerateClick(generatedImage); // Triggering on `2`
//       } else if (e.key === "5") {
//         handleBackClick(); // Triggering on `5`
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [generatedImage]);

//   return (
//     <div style={{
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       fontFamily: "Arial, sans-serif",
//       color: "#333",
//       padding: '21px',
//     }}>
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//         }}
//         onClick={handleBackClick}
//       >
//         Back
//         <p style={{
//           margin: "0 0 0 5px",
//           fontSize: "12px",
//           textAlign: "center"
//         }}>
//           5
//         </p>
//       </div>

//       <div style={{ marginTop: "10px", width: "100%", textAlign: "center" }}>
//         {isLoading ? (
//           <div className="loader" style={{ padding: "20px" }}>Loading...</div>
//         ) : (
//           <img
//             src={generatedImage}
//             alt="Generated"
//             style={{
//               width: "100%",
//               height: "100px",
//               objectFit: "cover",
//               border: "2px solid #007bff",
//               borderRadius: "8px",
//               transition: "transform 0.3s ease, box-shadow 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.transform = "scale(1.05)";
//               e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.transform = "scale(1)";
//               e.target.style.boxShadow = "none";
//             }}
//           />
//         )}
//       </div>

//       <div style={{ marginTop: "10px" }}>
//         <button
//           onClick={() => handleGenerateClick(generatedImage)}
//           style={{
//             padding: "8px 16px",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "14px",
//             backgroundColor: "#007bff",
//             color: "white",
//             cursor: "pointer",
//             transition: "background-color 0.3s, transform 0.2s",
//           }}
//           onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
//           onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
//         >
//           Send
//         </button>
//         <p style={{
//           margin: "5px 0 0",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#555"
//         }}>
//           2
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Page5;


// image load
import React, { useEffect, useState } from "react";
import { Undo2 } from 'lucide-react';
import Loader from './Loader';

const Page5 = ({ setCurrentAiPage, generatedImage, isLoading, sendMessage }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleBackClick = () => {
    setCurrentAiPage(1);
  };

  const handleGenerateClick = (generatedImage) => {
    console.log("i have data->", generatedImage);
    sendMessage(generatedImage);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "2") {
        handleGenerateClick(generatedImage);
      } else if (e.key === "5") {
        handleBackClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [generatedImage]);

  const loaderContainerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
  };


  console.log("generatedImage-->",generatedImage)
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "Arial, sans-serif",
      color: "#333",
      padding: '31px 52px',
    }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleBackClick}
      >
        {/* <Undo2 size={15}/>
        <p style={{
          margin: "0 0 0 5px",
          fontSize: "12px",
          textAlign: "center"
        }}>
          5
        </p> */}
            <p  style={{
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
        }}> <Undo2 size={15}/><span >(5)</span></p>
      </div>

      <div style={{ 
        marginTop: "10px", 
        width: "100%", 
        textAlign: "center", 
        position: "relative",
        height: "100px" 
      }}>
        {(isLoading || !imageLoaded) && (
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "109%",
            height: "100%",
            backgroundColor: "#f8f9fa",
            border: "2px solid #007bff",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Loader/>
          </div>
        )}
        {generatedImage && (
          <img
            src={generatedImage}
            // src="www.google.com/dfdfd"
            alt="Generated"
            style={{
              width: "100%",
              height: "100px",
              objectFit: "cover",
              border: "2px solid #007bff",
              borderRadius: "8px",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              opacity: imageLoaded ? 1 : 0,
              display: imageLoaded ? "block" : "none"
            }}
            onLoad={() => setImageLoaded(true)}
            onMouseOver={(e) => {
              e.target.style.transform = "scale(1.05)";
              e.target.style.boxShadow = "0 4px 10px rgba(0, 123, 255, 0.3)";
            }}
            onMouseOut={(e) => {
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "none";
            }}
          />
        )}
      </div>

      <div style={{ marginTop: "10px" }}>
        <button
          onClick={() => handleGenerateClick(generatedImage)}
          style={{
            padding: "8px 16px",
            border: "2px solid #007bff",
            borderRadius: "8px",
            fontSize: "14px",
            backgroundColor: "#007bff",
            color: "white",
            cursor: "pointer",
            transition: "background-color 0.3s, transform 0.2s",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
        >
          Send
        </button>
        <p style={{
          margin: "5px 0 0",
          fontSize: "12px",
          textAlign: "center",
          color: "#555"
        }}>
          2
        </p>
      </div>
    </div>
  );
};

export default Page5;