// App.js (Main React Entry)
import React from 'react';
import CreateEvent from './CreateEvent';
import CenteredButton from './CenteredButton';

function App() {
  return (
    // <div className="App">
    //   <h1>Event Creator</h1>
    //   <CreateEvent />
    // </div>
    <>
    <CenteredButton/>
    </>
  );
}

export default App;
