// import React from 'react';
// import { QrCode } from 'lucide-react';

// function ShowInfo() {
//   return (
//     <div className="show-info">
//       <div className="show-info-content">
//         <div className="show-info-details">
//           <p className="show-info-detail">Show: First Dates Hotel</p>
//           <p className="show-info-detail">Date: 13-04-2022</p>
//           <p className="show-info-detail">Time: 15:30 - 16:45</p>
//         </div>
//         <div className="show-info-share">
//           <div className="qr-code-container">
//           <p className="show-info-invite">Share the QR code or URL to invite guests to join the watch group session.</p>
//             <div>
//                 <QrCode size={120} />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ShowInfo;


// api data
import React, { useEffect, useState } from "react";
import QRCode from "qrcode";

function ShowInfo({ roomDetail }) {
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState(null);

  useEffect(() => {
    if (roomDetail?.qrUrl) {
      QRCode.toDataURL(roomDetail.qrUrl)
        .then((qrCodeUrl) => {
          setQrCodeImageUrl(qrCodeUrl);
        })
        .catch((err) => {
          console.error("Error generating QR code:", err);
        });
    }
  }, [roomDetail?.qrUrl]);

  return (
    <div className="show-info">
      <div className="show-info-content">
        <div className="show-info-details">
          {roomDetail ? (
            <>
              <p className="show-info-detail">Room Name: {roomDetail.roomName}</p>
              <p className="show-info-detail">Host User: {roomDetail.hostUserId}</p>
              <p className="show-info-detail">
                Max Participants: {roomDetail.maxParticipants}
              </p>
              <p className="show-info-detail">
                Created At: {new Date(roomDetail.createdAt).toLocaleString()}
              </p>
            </>
          ) : (
            <p>Loading room details...</p>
          )}
        </div>
        <div className="show-info-share">
          <div className="qr-code-container">
            <p className="show-info-invite">
              Share the QR code or URL to invite guests to join the watch group session.
            </p>
            {qrCodeImageUrl ? (
              <img
                src={qrCodeImageUrl}
                alt="QR Code"
                style={{ width: "120px", height: "120px" }}
              />
            ) : (
              <p>Generating QR Code...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowInfo;
