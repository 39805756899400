// // import React, { useState } from "react";

// // const CenteredButton = () => {

// //     const [isToggled, setIsToggled] = useState(false);
// //   // Function to handle the button click
// //   const handleClick = () => {
// //     if (window.JavaFun && typeof window.JavaFun.adjustWatchRoomWebViewScreen === "function") {
// //       window.JavaFun.adjustWatchRoomWebViewScreen(false);
// //       setIsToggled()
// //     } else {
// //       console.warn("JavaFun or adjustWatchRoomWebViewScreen is not available.");
// //     }
// //   };

// //   return (
// //     <div style={styles.container}>
// //       <button style={styles.button} onClick={handleClick}>
// //         Click Me
// //       </button>
// //     </div>
// //   );
// // };

// // const styles = {
// //   container: {
// //     display: "flex",
// //     justifyContent: "center",
// //     alignItems: "center",
// //     height: "100vh",
// //     backgroundColor: "red",
// //   },
// //   button: {
// //     padding: "10px 20px",
// //     fontSize: "16px",
// //     backgroundColor: "#007BFF",
// //     color: "white",
// //     border: "none",
// //     borderRadius: "5px",
// //     cursor: "pointer",
// //   },
// // };

// // export default CenteredButton;


// //new code
// import React, { useState } from "react";

// const CenteredButton = () => {
//   const [isToggled, setIsToggled] = useState(true);

//   // Function to handle the button click
//   const handleClick = () => {
//     if (window.JavaFun && typeof window.JavaFun.adjustWatchRoomWebViewScreen === "function") {
//       window.JavaFun.adjustWatchRoomWebViewScreen(!isToggled); // Pass the toggled state
//       setIsToggled((prevState) => !prevState); // Toggle the state
//     } else {
//       console.warn("JavaFun or adjustWatchRoomWebViewScreen is not available.");
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <button style={styles.button} onClick={handleClick}>
//         {isToggled ? "Turn Off" : "Turn On"}
//       </button>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     height: "100vh",
//     backgroundColor: "red",
//   },
//   button: {
//     padding: "10px 20px",
//     fontSize: "16px",
//     backgroundColor: "#007BFF",
//     color: "white",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//   },
// };

// export default CenteredButton;


// with mac
import React, { useState, useEffect } from "react";

const CenteredButton = () => {
  const [isToggled, setIsToggled] = useState(true);
  const [macAddress, setMacAddress] = useState(""); // State to hold the MAC address

  // Function to handle the button click
  const handleClick = () => {
    if (window.JavaFun && typeof window.JavaFun.adjustWatchRoomWebViewScreen === "function") {
      window.JavaFun.adjustWatchRoomWebViewScreen(!isToggled); // Pass the toggled state
      setIsToggled((prevState) => !prevState); // Toggle the state
    } else {
      console.warn("JavaFun or adjustWatchRoomWebViewScreen is not available.");
    }
  };

  // Fetch the MAC address when the component mounts
  useEffect(() => {
    if (window.JavaFun && typeof window.JavaFun.getMacAddressOfTV === "function") {
      const mac = window.JavaFun.getMacAddressOfTV(); // Fetch the MAC address
      setMacAddress(mac); // Update the state with the MAC address
    } else {
      console.warn("JavaFun or getMacAddressOfTV is not available.");
    }
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div style={styles.container}>
      <button style={styles.button} onClick={handleClick}>
        {isToggled ? "Turn Off" : "Turn On"}
      </button>
      <p style={styles.macText}>MAC Address: {macAddress || "Not Available"}</p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "red",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  macText: {
    marginTop: "20px",
    fontSize: "18px",
    color: "white",
  },
};

export default CenteredButton;
