// import React, { useState, useEffect, useRef } from 'react';
// import './VideoAdBanner.css';

// const VideoAdBanner = () => {
//   const [showAd, setShowAd] = useState(false);
//   const [videoEnded, setVideoEnded] = useState(false);
//   const [videoname, setVideoname] = useState('');
//   const [videoDuration, setVideoDuration] = useState(0);
//   const [skipCountdown, setSkipCountdown] = useState(5);
//   const [isUnskippable, setIsUnskippable] = useState(false);
//   const videoRef = useRef(null);
//   const countdownIntervalRef = useRef(null);
//   const autoSkipTimeoutRef = useRef(null);

//   const adVideoSrcName = ['ad1.mp4', 'ad2.mp4', 'ad3.mp4'];

//   const getRandomVideo = () => {
//     const randomIndex = Math.floor(Math.random() * adVideoSrcName.length);
//     return adVideoSrcName[randomIndex];
//   };

//   const setupAd = () => {
//     setVideoname(getRandomVideo());
//     setShowAd(true);
//     setVideoEnded(false);
//     setSkipCountdown(5);

//     // Determine if unskippable based on even/odd
//     const randomNumber = Math.floor(Math.random() * 100);
//     const newIsUnskippable = randomNumber % 2 === 0;
//     setIsUnskippable(newIsUnskippable);

//     if (videoRef.current) {
//       videoRef.current.currentTime = 0;
//       videoRef.current.play();
//     }

//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }
//     if (autoSkipTimeoutRef.current) {
//       clearTimeout(autoSkipTimeoutRef.current);
//     }

//     if (!newIsUnskippable) {
//       countdownIntervalRef.current = setInterval(() => {
//         setSkipCountdown((prevCount) => {
//           if (prevCount <= 1) {
//             clearInterval(countdownIntervalRef.current);
//             return 0;
//           }
//           return prevCount - 1;
//         });
//       }, 1000);
//     } else {
//       // Auto-skip after 10 seconds for unskippable ads
//       autoSkipTimeoutRef.current = setTimeout(() => {
//         setVideoEnded(true); // Mark video as ended to allow skipping
//         handleSkip();
//       }, 10000);
//     }
//   };

//   const scheduleReappear = () => {
//     setTimeout(() => {
//       setupAd();
//     }, 10000);
//   };

//   useEffect(() => {
//     setupAd();
//     return () => {
//       if (countdownIntervalRef.current) {
//         clearInterval(countdownIntervalRef.current);
//       }
//       if (autoSkipTimeoutRef.current) {
//         clearTimeout(autoSkipTimeoutRef.current);
//       }
//     };
//   }, []);

//   const handleSkip = () => {
//     setShowAd(false);
//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }
//     if (autoSkipTimeoutRef.current) {
//       clearTimeout(autoSkipTimeoutRef.current);
//     }
//     scheduleReappear();
//   };

//   const handleVideoEnd = () => {
//     setVideoEnded(true);
//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }
//     if (autoSkipTimeoutRef.current) {
//       clearTimeout(autoSkipTimeoutRef.current);
//     }
//     // Auto-skip the ad when it ends
//     handleSkip();
//   };

//   const handleLoadedMetadata = () => {
//     if (videoRef.current) {
//       setVideoDuration(Math.ceil(videoRef.current.duration));
//     }
//   };

//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     showAd && (
//       <div className="video-ad-banner">
//         <video
//           ref={videoRef}
//           src="https://watchparty.tpvai.com/b/uploads/ad3.mp4"
//           controls={false}
//           autoPlay
//           onEnded={handleVideoEnd}
//           onLoadedMetadata={handleLoadedMetadata}
//           muted
//         ></video>
//         {isUnskippable ? (
//           <button
//             className={`skip-button ${!videoEnded ? 'disabled' : ''}`}
//             onClick={handleSkip}
//             disabled={!videoEnded}
//           >
//             {!videoEnded
//               ? `Unskippable Ads`
//               : 'Skip Ad'}
//           </button>
//         ) : (
//           <button
//             className={`skip-button ${skipCountdown > 0 ? 'disabled' : ''}`}
//             onClick={handleSkip}
//             disabled={skipCountdown > 0}
//           >
//             {skipCountdown > 0 ? `Skip Ad in ${skipCountdown}` : 'Skip Ad'}
//           </button>
//         )}
//       </div>

//       // <>
//       // </>
//     )
//   );
// };

// export default VideoAdBanner;


//hide unskipble ads
// import React, { useState, useEffect, useRef } from 'react';
// import './VideoAdBanner.css';

// const VideoAdBanner = () => {
//   const [showAd, setShowAd] = useState(false);
//   const [videoEnded, setVideoEnded] = useState(false);
//   const [videoname, setVideoname] = useState('');
//   const [videoDuration, setVideoDuration] = useState(0);
//   const [skipCountdown, setSkipCountdown] = useState(5);
//   const [adSkipped, setAdSkipped] = useState(false); // New state to track if ad is skipped
//   const videoRef = useRef(null);
//   const countdownIntervalRef = useRef(null);

//   const adVideoSrcName = ['ad1.mp4', 'ad2.mp4', 'ad3.mp4'];

//   const getRandomVideo = () => {
//     const randomIndex = Math.floor(Math.random() * adVideoSrcName.length);
//     return adVideoSrcName[randomIndex];
//   };

//   const setupAd = () => {
//     if (adSkipped) return; // Prevent ad setup if skipped

//     setVideoname(getRandomVideo());
//     setShowAd(true);
//     setVideoEnded(false);
//     setSkipCountdown(5);

//     if (videoRef.current) {
//       videoRef.current.currentTime = 0;
//       videoRef.current.play();
//     }

//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }

//     countdownIntervalRef.current = setInterval(() => {
//       setSkipCountdown((prevCount) => {
//         if (prevCount <= 1) {
//           clearInterval(countdownIntervalRef.current);
//           return 0;
//         }
//         return prevCount - 1;
//       });
//     }, 1000);
//   };

//   useEffect(() => {
//     setupAd();
//     return () => {
//       if (countdownIntervalRef.current) {
//         clearInterval(countdownIntervalRef.current);
//       }
//     };
//   }, []);

//   const handleSkip = () => {
//     setShowAd(false);
//     setAdSkipped(true); // Mark ad as skipped

//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }
//   };

//   const handleVideoEnd = () => {
//     setVideoEnded(true);
//     if (countdownIntervalRef.current) {
//       clearInterval(countdownIntervalRef.current);
//     }
//     handleSkip();
//   };

//   const handleLoadedMetadata = () => {
//     if (videoRef.current) {
//       setVideoDuration(Math.ceil(videoRef.current.duration));
//     }
//   };

//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//   };

//   return (
//     showAd && (
//       <div className="video-ad-banner">
//         <video
//           ref={videoRef}
//           src="https://watchparty.tpvai.com/b/uploads/ad3.mp4"
//           controls={false}
//           autoPlay
//           onEnded={handleVideoEnd}
//           onLoadedMetadata={handleLoadedMetadata}
//           muted
//         ></video>
//         <button
//           className={`skip-button ${skipCountdown > 0 ? 'disabled' : ''}`}
//           onClick={handleSkip}
//           disabled={skipCountdown > 0}
//         >
//           {skipCountdown > 0 ? `Skip Ad in ${skipCountdown}` : 'Skip Ad'}
//         </button>
//       </div>
//     )
//   );
// };

// export default VideoAdBanner;



// new bug fixed
import React, { useState, useEffect, useRef } from 'react';
import './VideoAdBanner.css';

const VideoAdBanner = () => {
  const [showAd, setShowAd] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [videoname, setVideoname] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [skipCountdown, setSkipCountdown] = useState(5);
  const [adSkipped, setAdSkipped] = useState(false); // Tracks if the ad was skipped
  const videoRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const adVideoSrcName = ['ad1.mp4', 'ad2.mp4', 'ad3.mp4'];

  const getRandomVideo = () => {
    const randomIndex = Math.floor(Math.random() * adVideoSrcName.length);
    // return adVideoSrcName[randomIndex];
    const d1 = `https://watchparty.tpvai.com/b/uploads/${adVideoSrcName[randomIndex]}`
    return d1
  };

  const setupAd = () => {
    if (adSkipped) return; // Prevent ad setup if skipped

   
    setVideoname(getRandomVideo());
    setShowAd(true);
    setVideoEnded(false);
    setSkipCountdown(5);

    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    countdownIntervalRef.current = setInterval(() => {
      setSkipCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(countdownIntervalRef.current);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  console.log("getRandomVideo-->",getRandomVideo())

  useEffect(() => {
    setupAd(); // Initialize the first ad
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  const handleSkip = () => {
    setShowAd(false);
    setAdSkipped(true); // Mark ad as skipped

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }
  };

  const handleVideoEnd = () => {
    setVideoEnded(true);
    setShowAd(false); // Hide the ad banner after it ends
    setAdSkipped(false); // Reset adSkipped so that ads will continue to reappear

    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    // Re-trigger the ad after a delay if it wasn't skipped
    setTimeout(() => {
      setupAd();
    }, 10000); // 10 seconds delay before the ad appears again
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setVideoDuration(Math.ceil(videoRef.current.duration));
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    showAd && (
      <div className="video-ad-banner">
        <video
          ref={videoRef}
          src={videoname}
          controls={false}
          autoPlay
          onEnded={handleVideoEnd}
          onLoadedMetadata={handleLoadedMetadata}
          muted
        ></video>
        <button
          className={`skip-button ${skipCountdown > 0 ? 'disabled' : ''}`}
          onClick={handleSkip}
          disabled={skipCountdown > 0}
        >
          {skipCountdown > 0 ? `Skip Ad in ${skipCountdown}` : 'Skip Ad'}
        </button>
      </div>
    )
  );
};

export default VideoAdBanner;
