// import React, { useEffect,useState} from "react";
// import {useNavigate,useLocation } from "react-router-dom";
// import ShowInfo from './ShowInfo';
// import GroupList from './GroupList';
// import ChatPanel from './ChatPanel';
// import './styles.css';


// const users = [
//   { id: '1', name: 'Sonia', initial: 'S', color: '#ec4899', isOnline: true },
//   { id: '2', name: 'Roger', initial: 'R', color: '#3b82f6', isHost: true, isOnline: false },
//   // { id: '3', name: 'Viraj', initial: 'V', color: '#3b82f6', isOnline: true },
// ];

// const messages = [
//   { id: '1', userId: '2', text: 'See you at show time!', timestamp: new Date() }
//   // ,{ id: '1', userId: '1', text: 'See you at show time!', timestamp: new Date() },
// ];

// function MainWatchRoom() {
//     const navigate = useNavigate();
//   const location = useLocation();
//    const [rooms, setRooms] = useState([]);
//     const [roomDetail, setRoomDetail] = useState();
//   const roomId = location.state?.roomId || {};
  
//   useEffect(() => {
//     if (roomId) {
//       console.log("roomId Details:", roomId);
//       setRooms(roomId)
//       fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setRoomDetail(data); // Store the fetched rooms data in state
//       })
//     } else {
//       console.log("No matching program found.");
//     }
//   }, []);

//   // return (
//   //   <div className="app-w">
//   //     <div className="main-content">
//   //       <h1 className="room-title">rooms</h1>
//   //       <ShowInfo />
//   //     </div>
//   //     <div className="sidebar">
//   //       <GroupList users={users} />
//   //       <ChatPanel messages={messages} users={users} />
//   //     </div>
//   //   </div>
//   // );
//   return (
//     <div className="app-w">
//       <div className="main-content">
//       <button onClick={() =>  navigate('/event')}>back</button>
//         <h1 className="room-title">Room Details</h1>
//         {roomId ? (
//           // Display roomId as a string
//           typeof roomId === "object" ? (
//             <pre>{JSON.stringify(roomId, null, 2)}</pre> // Nicely formatted JSON
//           ) : (
//             <p>Room ID: {roomId}</p>
//           )
//         ) : (
//           <p>No Room ID available.</p>
//         )}
//         <ShowInfo />
//       </div>
//       <div className="sidebar">
//         <GroupList users={users} />
//         <ChatPanel messages={messages} users={users} />
//       </div>
//     </div>
//   );
// }

// export default MainWatchRoom;


// new api data
// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import ShowInfo from "./ShowInfo";
// import GroupList from "./GroupList";
// import ChatPanel from "./ChatPanel";
// import "./styles.css";

// function MainWatchRoom() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [roomDetail, setRoomDetail] = useState(null);
//   const roomId = location.state?.roomId || null;

//   useEffect(() => {
//     if (roomId) {
//       fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
//         .then((response) => response.json())
//         .then((data) => {
//           setRoomDetail(data); // Store the fetched room data in state
//         })
//         .catch((error) => console.error("Error fetching room details:", error));
//     } else {
//       console.log("No room ID provided.");
//     }
//   }, [roomId]);

//   // Transform participants for GroupList
//   const participants =
//     roomDetail?.participants?.map((participant) => ({
//       id: participant.userId,
//       name: participant.userId,
//       initial: participant.userId.charAt(0).toUpperCase(),
//       color: "#3b82f6", // You can customize colors here
//       isOnline: true, // Default to online; update with real status if available
//     })) || [];

//   return (
//     <div className="app-w">
//       <div className="main-content">
//         <button onClick={() => navigate("/event")}>Back</button>
//         {/* <h1 className="room-title">Room Details</h1> */}
//         {roomDetail ? (
//           <>
//           <h1 className="room-title">{roomDetail.roomName}</h1>
//             {/* <p>Room Name: {roomDetail.roomName}</p>
//             <p>Host User: {roomDetail.hostUserId}</p>
//             <p>Max Participants: {roomDetail.maxParticipants}</p>
//             <p>
//               Created At: {new Date(roomDetail.createdAt).toLocaleString()}
//             </p> */}
//           </>
//         ) : (
//           <p>Loading room details...</p>
//         )}
//         <ShowInfo qrUrl={roomDetail?.qrUrl} />
//       </div>
//       <div className="sidebar">
//         <GroupList users={participants} />
//         <ChatPanel messages={[]} users={participants} />
//       </div>
//     </div>
//   );
// }

// export default MainWatchRoom;




// new
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ShowInfo from "./ShowInfo";
import GroupList from "./GroupList";
import ChatPanel from "./ChatPanel";
import "./stylesInfo.css";

function MainWatchRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const [roomDetail, setRoomDetail] = useState(null);
  const roomId = location.state?.roomId || null;

  useEffect(() => {
    if (roomId) {
      fetch(`https://watchparty.tpvai.com/b/api/getWatchRoom/${roomId}`)
        .then((response) => response.json())
        .then((data) => {
          setRoomDetail(data); // Store the fetched room data in state
        })
        .catch((error) => console.error("Error fetching room details:", error));
    } else {
      console.log("No room ID provided.");
    }
  }, [roomId]);

  // Transform participants for GroupList
  const participants =
    roomDetail?.participants?.map((participant) => ({
      id: participant.userId,
      name: participant.userId,
      initial: participant.userId.charAt(0).toUpperCase(),
      color: "#3b82f6", // You can customize colors here
      isOnline: true, // Default to online; update with real status if available
    })) || [];

  return (
    <div className="app-w">
      <div className="main-content">
      <h1 className="room-title">Room Details</h1>
        <button onClick={() => navigate("/event")}>Back</button>
        {roomDetail ? (
          <ShowInfo roomDetail={roomDetail} />
        ) : (
          <p>Loading room details...</p>
        )}
      </div>
      <div className="sidebar">
        <GroupList users={participants} />
        <ChatPanel messages={[]} users={participants} />
      </div>
    </div>
  );
}

export default MainWatchRoom;
