// import React, { useState, useEffect, useRef, useCallback } from "react";
// import { Play, Pause } from 'lucide-react';
// import '../App.css';
// import axios from "axios";
// import Swal from 'sweetalert2';
// import AdBanner from './ads/AdBanner'
// import Page2 from './ai components/Page2'
// import Page3 from './ai components/Page3'
// import Page4 from './ai components/Page4'
// import Page5 from './ai components/Page5'

// const styles = {
//   textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
//   textFieldContainer: { flex: 1, marginRight: 12 },
//   gridItem: { paddingTop: 12, paddingBottom: 12 },
//   gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
//   gridItemMessage: { marginTop: 12, marginBottom: 12 },
//   sendButton: { backgroundColor: "transparent" },
//   sendIcon: { color: "white" },
//   mainGrid: { paddingTop: 100, borderWidth: 1 },
//   buttonContainer: {
//     position: 'fixed',
//     bottom: '8px',
//     left: 0,
//     right: 0,
//     display: 'grid',
//     justifyContent: 'center',
//     backgroundColor: 'transparent',
//   },
//   buttonT: {
//     background: 'linear-gradient(to right, #00c9ff, #928ce5)',
//     border: 'none',
//     borderRadius: '25px',
//     color: 'white',
//     fontSize: '18px',
//     padding: '6px 26px',
//     textAlign: 'center',
//     textDecoration: 'none',
//     display: 'inline-block',
//     boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
//     cursor: 'pointer',
//   },
//   span: {
//     cursor: 'pointer',
//   },
//   spanContainer:{
//     textAlign:'-webkit-center',
//     marginTop:'3px'
//   }
// };

// function extractSpecificUsername(email) {
//   var usernameRegex = /\/([^@\/]+)/;
//   var usernameMatch = email.match(usernameRegex);
//   return usernameMatch ? usernameMatch[1] : null;
// }

// function extractLastPathPart(url) {
//   const urlParts = url.split('/');
//   return urlParts[urlParts.length - 1];
// }

// function getStringBeforeAtSymbol(inputString) {
//   const atIndex = inputString.indexOf('@');
//   return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
// }

// const HARDCODED_EMOJIS = [
//   {"id":1,"url":"/images/GENRE_CHILDREN/sticker (1).png","paid":false,"category":"GENRE_CHILDREN","type":"png"}
// ];

// const ChatScreen = ({ username, socket }) => {
//   const [text, setText] = useState("");
//   const [messages, setMessages] = useState([]);
//   const [usernameid, setUsernameid] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [channel, setChannel] = useState(null);
//   const [floatingEmojis, setFloatingEmojis] = useState([]);
//   const [messageInput, setMessageInput] = useState('');
//   const [roomname, setRoomname] = useState('');
//   const [showMore, setShowMore] = useState(false);
//   const [additionalEmojis, setAdditionalEmojis] = useState([]);
//   const [isPaymentDone, setIsPaymentDone] = useState(false);

//   const [ProgramInfo, setProgramInfo] = useState(null);


//   //start
//   const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
//   const audioRefs = useRef([]);

// //Voice Chat
//   const [recording, setRecording] = useState(false);
//   const [uploadedURL, setUploadedURL] = useState("no audio found");
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorderRef = useRef(null);
//   const audioChunks = useRef([]);
//   const audioRef = useRef(null);


//     //new changes for ui
//     const [emojis, setEmojis] = useState([]);
//     const [startIndex, setStartIndex] = useState(0);
//     const [clickedImage, setClickedImage] = useState(null);

//     //ai
//     const [generatedImage, setGeneratedImage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);


//   //audio
//   const [globalValue, setGlobalValue] = useState(window.globalState.value);
//   const [playingIndex, setPlayingIndex] = useState(null);

//     const imagesPerPage = 6;
  
//     const emojiRefs = useRef([]);


//   const recordTimeoutRef = useRef(null)


//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorderRef.current = new MediaRecorder(stream);
  
//     mediaRecorderRef.current.ondataavailable = (event) => {
//       audioChunks.current.push(event.data);
//     };
  
//     mediaRecorderRef.current.onstop = () => {
//       const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
//       console.log("audioBlob-->", audioBlob);
//       setAudioBlob(audioBlob);
//       uploadAudio(audioBlob); // Upload after recording is stopped
//     };
  
//     mediaRecorderRef.current.start();
//     setRecording(true);
  
//     // Automatically stop recording after 10 seconds
//     recordTimeoutRef.current = setTimeout(() => {
//       stopRecording();
//     }, 10000); // 10000 ms = 10 seconds
//   };

//   const stopRecording = () => {
//     mediaRecorderRef.current.stop();
//     setRecording(false);
//     audioChunks.current = []; // Clear the chunks after recording
  
//     // Clear the timeout if the recording stops before 10 seconds
//     if (recordTimeoutRef.current) {
//       clearTimeout(recordTimeoutRef.current);
//     }
//   };
  

//   // Upload Audio
//   const uploadAudio = async (blob) => {
//     const formData = new FormData();
//     formData.append("audio", blob, "audio.wav");

//     try {
//       const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
//         headers: { "Content-Type": "multipart/form-data" },
//       });
//       setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
//       sendMessage(response.data.uploadedURL)
//     } catch (error) {
//       console.error("Upload error:", error);
//     }
//   };

  
//   // Adding play and stop audio functionalities
//   // const playAudio = (index) => {


//   //   if (window.JavaFun) {
//   //     const audiourl = uploadedURL
//   //     const stateaudio = true
//   //     window.JavaFun.audioPlayStatus(audiourl,stateaudio);
//   //         // window.JavaFun.audioPlayStatus(true);
//   //   }
//   // };
//   const playAudio = (index) => {
//     // if (!uploadedURL) {
//     //   console.error("Audio URL is not available");
//     //   return;
//     // }

//     // if (window.JavaFun) {
//     //   const audiourl = uploadedURL;
//     //   const stateaudio = true;
//     //   window.JavaFun.audioPlayStatus(audiourl, stateaudio);
//     //   // setTimeout(() => {
//     //   //   window.JavaFun.audioPlayStatus(audiourl, stateaudio);
//     //   // }, 10000);
//     // }

//     // // if (audioRefs.current[index]) {
//     // //   audioRefs.current[index].play();
//     // // }


//     // new one
//     if (playingIndex !== null && playingIndex !== index) {
//       stopAudio(playingIndex); // Stop currently playing audio
//     }

//     const audioElement = audioRefs.current[index];
//     if (audioElement) {
//       // audioElement.play();
//       setPlayingIndex(index);

//       if (window.JavaFun) {
//         const audiourl = uploadedURL;
//         window.JavaFun.audioPlayStatus(audiourl, true);
//       }
//     }
//     console.log("playingIndex-2->",playingIndex)

//   };


//   const stopAudio = (index) => {
//     // if (audioRefs.current[index]) {
//     //   audioRefs.current[index].pause();
//     //   audioRefs.current[index].currentTime = 0;
//     //   setCurrentAudioIndex(null); // Reset current audio index
//     //   if (window.JavaFun) {
//     //     window.JavaFun.audioPlayStatus(false);
//     // }
//     // }

//     const audioElement = audioRefs.current[index];
//     if (audioElement) {
//       audioElement.pause();
//       audioElement.currentTime = 0;
//       setPlayingIndex(null);

//       if (window.JavaFun) {
//         const audiourl = uploadedURL;
//         window.JavaFun.audioPlayStatus(audiourl, false);
//       }
//     }
//   };

//   const togglePlayPause = (index) => {
//     if (playingIndex === index) {
//       stopAudio(index);
//     } else {
//       playAudio(index);
//     }
//   };

//   //Voice Chat

//   const scrollDiv = useRef(null);
//   const Button1Ref = useRef(null);
//   const Button2Ref = useRef(null);
//   const Button3Ref = useRef(null);
//   const Button4Ref = useRef(null);
//   const Button5Ref = useRef(null);
//   const Button6Ref = useRef(null);
//   const Button7Ref = useRef(null);
//   const Button8Ref = useRef(null);
//   const Button9Ref = useRef(null);
//   const Button10Ref = useRef(null);

//   const CurrentUser = localStorage.getItem("email");

//   useEffect(() => {
//     const storedEmail = localStorage.getItem('userId');
//     const username = storedEmail.split("@")[0];
//     setUsernameid(username);

//     const roomid = localStorage.getItem('roomid');
//     setRoomname(roomid);


//     //payment start
//     const paymentStatus = localStorage.getItem('paymentStatus');
//     if (paymentStatus === 'true') {
//       setIsPaymentDone(true);
//     }
//     //payment end

    

//     // api call
// const fetchEmojis = async () => {
//   try {
//     setLoading(true);
//     const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     let category = storeProgramInfo;
//     // Remove any surrounding quotes from the category string, if present
//     if (typeof category === 'string') {
//       category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
//     }

//     console.log("sahu2new",category)
//     setProgramInfo(storeProgramInfo)
//     const response = await axios.get(
//       `https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`
//     );
//     setEmojis(response.data);
//     setLoading(false);
//   } catch (error) {
//     console.error("Error fetching emojis", error);
//     setLoading(false);
//   }
// };
//     fetchEmojis();
    
//     const handleKeyPress = (e) => {
//       if (e.key === "7") {
//         setCurrentAiPage(1)
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };

//   }, []);


//   useEffect(() => {
//     // Event handler for global state changes
//     const handleGlobalStateChange = (event) => {
//       setGlobalValue(event.detail); // Update the React state with the new global state value
//       setPlayingIndex(event.detail);
//       console.log("from andio js file",event.detail)

//     };

//     // Listen for the custom event
//     window.addEventListener("globalStateChanged", handleGlobalStateChange);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener("globalStateChanged", handleGlobalStateChange);
//     };
//   }, []);



  

//     //new changes ui
//     const handleNext = useCallback(() => {
//       if (startIndex + imagesPerPage < emojis.length) {
//         setStartIndex((prevIndex) => prevIndex + imagesPerPage);
//       }
//     }, [startIndex, emojis.length]);
  
//     const handlePrevious = useCallback(() => {
//       if (startIndex - imagesPerPage >= 0) {
//         setStartIndex((prevIndex) => prevIndex - imagesPerPage);
//       }
//     }, [startIndex]);
  
//     const handleImageClick = (index,emoji) => {
//       setClickedImage(index + 1);
//       console.log("emojis-new-audio-4",emoji.url)
//       const url =  `https://watchparty.tpvai.com/b${emoji.url}`
//       if (emoji.paid && !isPaymentDone) {
//         handlePaymentConfirmation();
//       } else {
//         sendMessage(url)
//       }
//     };
  
//   console.log("anu recording",recording)
//     useEffect(() => {
//       const handleKeyPress = (event) => {
//         const key = event.key;
  
//         if (key >= "1" && key <= "6") {
//           const index = parseInt(key) - 1;
//           if (emojiRefs.current[index]) {
//             console.log("emojis-new-audio-2",clickedImage)
//             emojiRefs.current[index].click();
//           }
//         // } else if (key === "ArrowRight") {
//         } else if (key === "0") {
//           handleNext();
//         } 
//         // else if (key === "9") {
//         //   console.log("anu 9")
//         //   recording ? stopRecording() : startRecording();
//         // }
//         else if (key === "8") {
//           handlePrevious();
//         }
//       };
  
//       window.addEventListener("keydown", handleKeyPress);
  
//       return () => {
//         window.removeEventListener("keydown", handleKeyPress);
//       };
//     }, [handleNext, handlePrevious]);
  

//     useEffect(() => {
//       const handleKeyDown = (event) => {
//         if (event.key === "9") {
//           if (recording) {
//             stopRecording();
//           } else {
//             startRecording();
//           }
//         }
//       };
  
//       window.addEventListener("keydown", handleKeyDown);
  
//       return () => {
//         window.removeEventListener("keydown", handleKeyDown);
//       };
//     }, [recording]);


  
//     console.log("emojis-new-audio-3",clickedImage)
  

//   const currentEmojis = emojis.slice(startIndex, startIndex + imagesPerPage);


//   const handlePaymentConfirmation = async () => {
//     const { value: formValues } = await Swal.fire({
//       title: 'Payment Confirmation',
//       html: `
//         <div style="text-align: center;">
//           <label for="swal-input1">Amount: <strong>$30</strong></label>
//         </div>
//         <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
//                maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
//         <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
//                pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
//         <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
//                maxlength="3" pattern="\\d{3}" inputmode="numeric">
//       `,
//       focusConfirm: false,
//       showCancelButton: true,
//       confirmButtonText: 'Pay',
//       cancelButtonText: 'Cancel',
//       preConfirm: () => {
//         const cardNumber = document.getElementById('swal-input1').value;
//         const expiryDate = document.getElementById('swal-input3').value;
//         const cvv = document.getElementById('swal-input2').value;
  
//         if (!cardNumber || !expiryDate || !cvv) {
//           Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
//           return false;
//         }
//         return { cardNumber, expiryDate, cvv };
//       },
//       customClass: {
//         popup: 'custom-swal-popup',
//       },
//     });
  
//     if (formValues) {
//       // Simulate payment success after user submits details
//       localStorage.setItem('paymentStatus', 'true');
//       setIsPaymentDone(true);
//     }
//   };
  
//   const showFloatingEmoji = (imageUrl) => {
//     if (imageUrl.endsWith('.png')) return; //not flying stickers
//     if (imageUrl.endsWith('.wav')) return;
//     const screenWidth = window.innerWidth;
//     const maxOffset = screenWidth / 4;
//     const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
//     const container = document.createElement('div');
//     container.className = 'floating-emoji-container';
//     container.style.left = `calc(50% + ${offset}px)`;
//     container.style.transform = 'translateX(-50%)';
//     const floatingEmoji = document.createElement('img');
//     floatingEmoji.className = 'floating-emoji';
//     floatingEmoji.src = imageUrl;
//     floatingEmoji.alt = 'Emoji';
//     floatingEmoji.style.width = '60px';
//     floatingEmoji.style.height = '60px';
//     container.appendChild(floatingEmoji);
//     document.body.appendChild(container);
//     const expirationTime = Date.now() + 2000;
//     setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

//     const cardRect = document.querySelector('.card').getBoundingClientRect();
//     const containerRect = container.getBoundingClientRect();
//     const emojiRect = floatingEmoji.getBoundingClientRect();

//     if (containerRect.bottom > cardRect.top) {
//       const newTop = cardRect.top - emojiRect.height - 10;
//       container.style.top = `${newTop}px`;
//     }
//   };

//   // ai
// //   const handleGenerateImage = async (prompt,audio_status) => {
// // //     setIsLoading(true);
// //     // try {
// //     //   const response = await fetch('https://watchparty.tpvai.com/b/generate-image', {
// //     //     method: 'POST',
// //     //     headers: {
// //     //       'Content-Type': 'application/json',
// //     //     },
// //     //     body: JSON.stringify({ prompt }),
// //     //   });
      
// // //       const data = await response.json();
// // //       console.log("data-->",data.imageurl)
// // //       setGeneratedImage(data.imageurl
// // // ); // Assuming the API returns { image: "imageUrl" }
// // //     setCurrentAiPage(3); // Navigate to Page5 after successful generation
// // //     } catch (error) {
// // //       console.error('Error generating image:', error);
// // //       alert('Failed to generate image. Please try again.');
// // //     } finally {
// // //       setIsLoading(false);
// // //     }


// // setIsLoading(true);
// // try {
// //   const response = await fetch(`https://watchparty.tpvai.com/b/aiimages/${prompt}`, {
// //     method: 'GET',
// //     headers: {
// //       'Content-Type': 'application/json',
// //     }
// //   });
  
// //   const data = await response.json();
// //   console.log("data-id->",data.imageUrl)
// //   setGeneratedImage(data.imageUrl
// // ); // Assuming the API returns { image: "imageUrl" }
// // setCurrentAiPage(2); // Navigate to Page5 after successful generation
// // } catch (error) {
// //   console.error('Error generating image:', error);
// //   alert('Failed to generate image. Please try again.');
// // } finally {
// //   setIsLoading(false);
// // }




// //   };

// const handleGenerateImage = async (prompt, audio_status) => {
//   setIsLoading(true);

//   try {
//     let response;
//     let data;

//     if (audio_status) {
//       // If audio_status is true, use POST request
//       response = await fetch('https://watchparty.tpvai.com/b/generate-image', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ prompt }),
//       });
//     } else {
//       // If audio_status is false, use GET request
//       response = await fetch(`https://watchparty.tpvai.com/b/aiimages/${prompt}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//     }
//     data = await response.json();
//     if (audio_status) {
//       setGeneratedImage(data.imageurl)
//     }else{
//       setGeneratedImage(data.imageUrl)
//     }
//     setCurrentAiPage(2);
//   } catch (error) {
//     console.error('Error generating image:', error);
//     alert('Failed to generate image. Please try again.');
//   } finally {
//     setIsLoading(false);
//   }
// };


//   useEffect(() => {
//     if (uploadedURL !== "no audio found") {
//       const newIndex = messages.length - 1;
//       setCurrentAudioIndex(newIndex);
//       // playAudio(newIndex);
//     }
//   }, [uploadedURL]);
  

//   useEffect(() => {
//     const handleMessage = (message) => {
//       const username = message.from;
//       const isWav = message.message.endsWith('.wav');
  
//       showFloatingEmoji(message.message);
//       setMessages((prevMessages) => [...prevMessages, message]);
//       console.log("anu play -1")
//       // Automatically play audio if the message is a .wav file
//       // if (isWav) {
//       //   console.log("anu play 0")
//       //   setTimeout(() => {
//       //     console.log("anu play 1")
//       //     const newIndex = messages.length; // Index of the new message
//       //     setCurrentAudioIndex(newIndex); // Store index to control the current audio
//       //     // audioRefs.current[newIndex].play(); // Auto-play the new audio
//       //     playAudio(newIndex);
//       //   }, 100); // Delay to ensure audio ref is set
//       // }

//       if (isWav) {
//         setUploadedURL(message.message);
//       }
//     };
  
//     socket.on("send-message-to-client", handleMessage);
  
//     return () => {
//       socket.off("send-message-to-client", handleMessage);
//     };
//   }, [messages, socket]);
  

  

//   const sendMessage = (messageToSend) => {
//     if (messageToSend) {
//       socket.emit("receive-message-from-client", messageToSend);
//       setMessageInput('');
//     }
//   };

//   const [currentAiPage, setCurrentAiPage] = useState('main');

//   const handleAiNext = () => {
//     const currentPageNum = parseInt(currentAiPage);
//     if (currentPageNum < 4) setCurrentAiPage(currentPageNum + 1);
//   };

//   const handleAiPrev = () => {
//     const currentPageNum = parseInt(currentAiPage);
//     if (currentPageNum > 1) setCurrentAiPage(currentPageNum - 1);
//   };

//   console.log("currentAiPage->",currentAiPage)

//   const renderAiPage = () => {
//     // switch(currentAiPage) {
//     //   case 1:
//     //     return (
//     //       <div className="card">
//     //         <Page2 setCurrentAiPage={setCurrentAiPage}/>
//     //       </div>
//     //     );
//     //   case 2:
//     //     return (
//     //       <div className="card">
//     //         <Page3 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage}/>
//     //       </div>
//     //     );
//     //   case 3:
//     //     return (
//     //       <div className="card">
//     //         <Page4 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage} />
//     //       </div>
//     //     );
//     //   case 4:
//     //     return (
//     //       <div className="card">
//     //       <Page5 setCurrentAiPage={setCurrentAiPage} generatedImage={generatedImage} isLoading={isLoading} sendMessage={sendMessage}/>
//     //       </div>
//     //     );
//     //   default:
//     //     return (
//     //       <div className="card">
//     //         <div className="container text-center mt-2" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
//     //           {loading ? (
//     //             <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
//     //               <span className="visually-hidden">Loading...</span>
//     //             </div>
//     //           ) : (
//     //             <>
//     //               <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//     //                 {currentEmojis.map((emoji, index) => (
//     //                   <div
//     //                     key={index}
//     //                     className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
//     //                     ref={(el) => (emojiRefs.current[index] = el)}
//     //                     onClick={() => handleImageClick(index, emoji)}
//     //                     style={{ margin: 0, padding: 0 }}
//     //                   >
//     //                     {emoji.paid && (
//     //                       <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
//     //                         P
//     //                       </span>
//     //                     )}
//     //                     <img
//     //                       src={`https://watchparty.tpvai.com/b${emoji.url}`}
//     //                       alt="emoji"
//     //                       className="img-fluid"
//     //                       style={{
//     //                         width: "23px",
//     //                         height: "23px",
//     //                         objectFit: "cover",
//     //                       }}
//     //                     />
//     //                     <span className="">{index + 1}</span>
//     //                   </div>
//     //                 ))}
//     //               </div>



//     //               <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//     //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//     //                   <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
//     //                     <img
//     //                       src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
//     //                       alt="previous"
//     //                       className="img-fluid"
//     //                       style={{
//     //                         width: "23px",
//     //                         height: "23px",
//     //                         objectFit: "cover",
//     //                       }}
//     //                     />
//     //                     <p className="mt-2">8</p>
//     //                   </div>
//     //                 </div>
//     //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//     //                   <div style={{ cursor: "pointer" }}>

//     //                     <div 
//     //                           tabIndex={0}  // Make the div focusable
//     //                           onClick={recording ? stopRecording : startRecording} 
//     //                           role="button"  // Add role for better accessibility
//     //                           style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
//     //                         >
//     //                           {recording ? (
//     //                             <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
//     //                           ) : (
//     //                             <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
//     //                           )}
//     //                           <p className="mt-2">9</p>
//     //                         </div>

//     //                   </div>
//     //                 </div>
//     //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//     //                   <div style={{ cursor: "pointer" }} onClick={handleNext}>
//     //                     <img
//     //                       src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
//     //                       alt="next"
//     //                       className="img-fluid"
//     //                       style={{
//     //                         width: "23px",
//     //                         height: "23px",
//     //                         objectFit: "cover",
//     //                       }}
//     //                     />
//     //                     <p className="mt-2">0</p>
//     //                   </div>
//     //                 </div>
//     //                 <div className="text-center">
//     //           <button style={{background: 'linear-gradient(to right, rgb(0, 201, 255), rgb(146, 140, 229))', border: 'none', borderRadius: '25px', color: 'white', fontSize: '14px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px', cursor: 'pointer'}}>AI Generate</button>
//     //           <div style={styles.spanContainer}>
//     //             <span  style={styles.span}>7</span>
//     //           </div>
//     //         </div>
//     //               </div>
//     //             </>
//     //           )}
//     //         </div>

//     //       </div>
//     //     );
//     // }

//     switch(currentAiPage) {
//       case 1:
//         return (
//           <div className="card">
//             <Page3 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage}/>
//           </div>
//         );
//       case 3:
//         return (
//           <div className="card">
//             <Page4 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage} />
//           </div>
//         );
//       case 2:
//         return (
//           <div className="card">
//           <Page5 setCurrentAiPage={setCurrentAiPage} generatedImage={generatedImage} isLoading={isLoading} sendMessage={sendMessage} currentAiPage={currentAiPage}/>
//           </div>
//         );
//       default:
//         return (
//           <div className="card">
//             <div className="container text-center mt-2" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
//               {loading ? (
//                 <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
//                   <span className="visually-hidden">Loading...</span>
//                 </div>
//               ) : (
//                 <>
//                   <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//                     {currentEmojis.map((emoji, index) => (
//                       <div
//                         key={index}
//                         className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
//                         ref={(el) => (emojiRefs.current[index] = el)}
//                         onClick={() => handleImageClick(index, emoji)}
//                         style={{ margin: 0, padding: 0 }}
//                       >
//                         {emoji.paid && (
//                           <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
//                             P
//                           </span>
//                         )}
//                         <img
//                           src={`https://watchparty.tpvai.com/b${emoji.url}`}
//                           alt="emoji"
//                           className="img-fluid"
//                           style={{
//                             width: "23px",
//                             height: "23px",
//                             objectFit: "cover",
//                           }}
//                         />
//                         <span className="circle-button-remote">{index + 1}</span>
//                       </div>
//                     ))}
//                   </div>



//                   <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
//                     <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//                       <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
//                         <img
//                           src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
//                           alt="previous"
//                           className="img-fluid"
//                           style={{
//                             width: "23px",
//                             height: "23px",
//                             objectFit: "cover",
//                           }}
//                         />
//                         <p className="mt-2"><span className="circle-button-remote">8</span></p>
//                       </div>
//                     </div>
//                     <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
//                       <div style={{ cursor: "pointer" }}>

//                         <div 
//                               tabIndex={0}  // Make the div focusable
//                               onClick={recording ? stopRecording : startRecording} 
//                               role="button"  // Add role for better accessibility
//                               style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
//                             >
//                               {recording ? (
//                                 <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
//                               ) : (
//                                 <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
//                               )}
//                               <p className="mt-2"><span className="circle-button-remote">9</span></p>
//                             </div>

//                       </div>
//                     </div>
//                     <div className="col-4 d-flex justify-content-center" style={{ margin: 0, padding: 0 }}>
//                       <div style={{ cursor: "pointer" }} onClick={handleNext}>
//                         <img
//                           src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
//                           alt="next"
//                           className="img-fluid"
//                           style={{
//                             width: "23px",
//                             height: "23px",
//                             objectFit: "cover",
//                           }}
//                         />
//                         <p className="mt-2"><span className="circle-button-remote">0</span></p>
//                       </div>
//                     </div>
//                     <div className="text-center">
//               <button style={{background: 'linear-gradient(to right, rgb(0, 201, 255), rgb(146, 140, 229))', border: 'none', borderRadius: '25px', color: 'white', fontSize: '14px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px', cursor: 'pointer'}}>AI Generate</button>
//               <div style={styles.spanContainer}>
//                 {/* <span  style={styles.span}>7</span> */}
//                 <p ><span className="circle-button-remote">7</span></p>
//               </div>
//             </div>
//                   </div>
//                 </>
//               )}
//             </div>

//           </div>
//         );
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid">
//       <div style={{textAlign:'center'}}>
//       <AdBanner/>
//       </div>
//         <div>
//           <div className="col-6 chatitem">
//               {messages.map((msg, index) => {
//                 const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
//                 const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
//                 const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
//                 const isGif = msg.message.endsWith('.gif');
//                 const isPng = msg.message.endsWith('.png');
//                 const isWav = msg.message.endsWith('.wav');

//                 if (isGif) {
//                   return null;
//                 }
//                 if (isPng) {
//                   return (
//                     <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle , padding:'10px 0' }}>
//                       <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
                
//                 // if (isWav) {
//                 //   return (
//                 //     <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
//                 //        {/* <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} /> */}
//                 //        <audio
//                 //         controls
//                 //         ref={(el) => (audioRefs.current[index] = el)} // Store the ref to each audio element
//                 //         src={msg.message}
//                 //         onPlay={() => playAudio(index)} // Trigger playAudio when playing
//                 //         onPause={() => stopAudio(index)} // Trigger stopAudio when pausing
//                 //       />
//                 //       <span className="autherusername">{userName}</span>
//                 //     </div>
//                 //   );
//                 // }
//                 if (isWav) {
//                   return (
//                     <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
//                       {/* The audio element will be hidden but still present for functionality */}
//                       <audio
//                         ref={(el) => (audioRefs.current[index] = el)} // Store the ref to each audio element
//                         src={msg.message}
//                         className="d-none" // Hide the audio element
//                         onEnded={() => setPlayingIndex(null)} // Reset on audio end
//                       />
                
//                       {/* Play/Pause button */}
//                       <button
//                         onClick={() => togglePlayPause(index)} // Toggle play/pause when clicked
//                         className="btn btn-primary rounded-circle p-3"
//                       >
//                         {playingIndex === index ? <Pause size={20} /> : <Play size={20} />}
//                       </button>
                      
//                       <span className="autherusername">{userName}</span>
//                     </div>
//                   );
//                 }
                
//               })}

//           </div>
//         </div>
//         <div className="row">
//           <div className="col-6 text-sm-end">
//           </div>
//         </div>
//       </div>

//       <footer className="fixed-bottom p-3">
//         <div className="container-fluid">
//           <div className="row justify-content-center">
//             <div className="col-auto">
//               {renderAiPage()}
//               {/* {currentAiPage !== 'main' && (
//                 <div className="navigation-buttons">
//                   <button 
//                     onClick={handleAiPrev} 
//                     disabled={currentAiPage === 1}
//                     className="nav-btn"
//                   >
//                     Previous
//                   </button>
//                   <span className="page-indicator">{currentAiPage} / 4</span>
//                   <button 
//                     onClick={handleAiNext} 
//                     disabled={currentAiPage === 4}
//                     className="nav-btn"
//                   >
//                     Next
//                   </button>
//                 </div>
//               )} */}
//             </div>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };


// export default ChatScreen;


//joined as url 21-01-25
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Play, Pause } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import '../App.css';
import axios from "axios";
import Swal from 'sweetalert2';
import AdBanner from './ads/AdBanner'
import Page2 from './ai components/Page2'
import Page3 from './ai components/Page3'
import Page4 from './ai components/Page4'
import Page5 from './ai components/Page5'

const styles = {
  textField: { width: "100%", borderWidth: 0, borderColor: "transparent" },
  textFieldContainer: { flex: 1, marginRight: 12 },
  gridItem: { paddingTop: 12, paddingBottom: 12 },
  gridItemChatList: { overflow: "auto", height: "74vh", width: "-webkit-fill-available" },
  gridItemMessage: { marginTop: 12, marginBottom: 12 },
  sendButton: { backgroundColor: "transparent" },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
  buttonContainer: {
    position: 'fixed',
    bottom: '8px',
    left: 0,
    right: 0,
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  buttonT: {
    background: 'linear-gradient(to right, #00c9ff, #928ce5)',
    border: 'none',
    borderRadius: '25px',
    color: 'white',
    fontSize: '18px',
    padding: '6px 26px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
  span: {
    cursor: 'pointer',
  },
  spanContainer:{
    textAlign:'-webkit-center',
    marginTop:'3px'
  }
};

function extractSpecificUsername(email) {
  var usernameRegex = /\/([^@\/]+)/;
  var usernameMatch = email.match(usernameRegex);
  return usernameMatch ? usernameMatch[1] : null;
}

function extractLastPathPart(url) {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
}

// function getStringBeforeAtSymbol(inputString) {
//   const atIndex = inputString.indexOf('@');
//   return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
// }
function getStringBeforeAtSymbol(inputString) {
  // If the input is null, undefined, or not a string, generate a random username
  if (!inputString || typeof inputString !== 'string') {
    return generateRandomUsername(); 
  }

  const atIndex = inputString.indexOf('@');
  return atIndex === -1 ? inputString : inputString.substring(0, atIndex);
}

// Helper function to generate a random username
function generateRandomUsername() {
  const randomName = 'user' + Math.random().toString(36).substring(2, 10);  // Generates a random alphanumeric string
  const randomNumber = Math.floor(Math.random() * 1000000);  // Generates a random number
  return `${randomName}@${randomNumber}`;  // Combine random name and number in format 'randomName@randomNumber'
}


const HARDCODED_EMOJIS = [
  {"id":1,"url":"/images/GENRE_CHILDREN/sticker (1).png","paid":false,"category":"GENRE_CHILDREN","type":"png"}
];

const ChatScreen = ({ username, socket }) => {
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [usernameid, setUsernameid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [floatingEmojis, setFloatingEmojis] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [roomname, setRoomname] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [additionalEmojis, setAdditionalEmojis] = useState([]);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [ProgramInfo, setProgramInfo] = useState(null);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);

  const location = useLocation();
  const audioRefs = useRef([]);

  // useEffect(() => {
  //   if (location.state?.fromUrl) {
  //     setUsernameid(location.state.username);
  //     setRoomname(location.state.roomName);
  //     setUsernameid(location.state.username)
  //   } else {
  //     const roomid = localStorage.getItem('roomid');
  //     setRoomname(roomid);
  //     setUsernameid(localStorage.getItem('username'));
  //   }
  // }, [location]);
  useEffect(() => {
    if (location.state?.fromUrl) {
      setUsernameid(location.state.username);
      setRoomname(location.state.roomName);
    } else {
      const roomid = localStorage.getItem('roomid');
      if (roomid) { // Check if roomid is not null
        setRoomname(roomid);
      }
      
      const username = localStorage.getItem('username');
      if (username) { // Check if username is not null
        setUsernameid(username);
      }
    }
  }, [location]);
  
  
//Voice Chat
  const [recording, setRecording] = useState(false);
  const [uploadedURL, setUploadedURL] = useState("no audio found");
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(null);


    //new changes for ui
    const [emojis, setEmojis] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [clickedImage, setClickedImage] = useState(null);

    //ai
    const [generatedImage, setGeneratedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  //audio
  const [globalValue, setGlobalValue] = useState(window.globalState.value);
  const [playingIndex, setPlayingIndex] = useState(null);

    const imagesPerPage = 6;
  
    const emojiRefs = useRef([]);


  const recordTimeoutRef = useRef(null)


  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
  
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.current.push(event.data);
    };
  
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      console.log("audioBlob-->", audioBlob);
      setAudioBlob(audioBlob);
      uploadAudio(audioBlob); // Upload after recording is stopped
    };
  
    mediaRecorderRef.current.start();
    setRecording(true);
  
    // Automatically stop recording after 10 seconds
    recordTimeoutRef.current = setTimeout(() => {
      stopRecording();
    }, 10000); // 10000 ms = 10 seconds
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    audioChunks.current = []; // Clear the chunks after recording
  
    // Clear the timeout if the recording stops before 10 seconds
    if (recordTimeoutRef.current) {
      clearTimeout(recordTimeoutRef.current);
    }
  };
  

  // Upload Audio
  const uploadAudio = async (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");

    try {
      const response = await axios.post("https://watchparty.tpvai.com/b/audioupload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setUploadedURL(response.data.uploadedURL); // Use the returned URL to play the audio
      sendMessage(response.data.uploadedURL)
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  
  // Adding play and stop audio functionalities
  // const playAudio = (index) => {


  //   if (window.JavaFun) {
  //     const audiourl = uploadedURL
  //     const stateaudio = true
  //     window.JavaFun.audioPlayStatus(audiourl,stateaudio);
  //         // window.JavaFun.audioPlayStatus(true);
  //   }
  // };
  const playAudio = (index) => {
    // if (!uploadedURL) {
    //   console.error("Audio URL is not available");
    //   return;
    // }

    // if (window.JavaFun) {
    //   const audiourl = uploadedURL;
    //   const stateaudio = true;
    //   window.JavaFun.audioPlayStatus(audiourl, stateaudio);
    //   // setTimeout(() => {
    //   //   window.JavaFun.audioPlayStatus(audiourl, stateaudio);
    //   // }, 10000);
    // }

    // // if (audioRefs.current[index]) {
    // //   audioRefs.current[index].play();
    // // }


    // new one
    if (playingIndex !== null && playingIndex !== index) {
      stopAudio(playingIndex); // Stop currently playing audio
    }

    const audioElement = audioRefs.current[index];
    if (audioElement) {
      // audioElement.play();
      setPlayingIndex(index);

      if (window.JavaFun) {
        const audiourl = uploadedURL;
        window.JavaFun.audioPlayStatus(audiourl, true);
      }
    }
    console.log("playingIndex-2->",playingIndex)

  };


  const stopAudio = (index) => {
    // if (audioRefs.current[index]) {
    //   audioRefs.current[index].pause();
    //   audioRefs.current[index].currentTime = 0;
    //   setCurrentAudioIndex(null); // Reset current audio index
    //   if (window.JavaFun) {
    //     window.JavaFun.audioPlayStatus(false);
    // }
    // }

    const audioElement = audioRefs.current[index];
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
      setPlayingIndex(null);

      if (window.JavaFun) {
        const audiourl = uploadedURL;
        window.JavaFun.audioPlayStatus(audiourl, false);
      }
    }
  };

  const togglePlayPause = (index) => {
    if (playingIndex === index) {
      stopAudio(index);
    } else {
      playAudio(index);
    }
  };

  //Voice Chat

  const scrollDiv = useRef(null);
  const Button1Ref = useRef(null);
  const Button2Ref = useRef(null);
  const Button3Ref = useRef(null);
  const Button4Ref = useRef(null);
  const Button5Ref = useRef(null);
  const Button6Ref = useRef(null);
  const Button7Ref = useRef(null);
  const Button8Ref = useRef(null);
  const Button9Ref = useRef(null);
  const Button10Ref = useRef(null);

  const CurrentUser = localStorage.getItem("email");

  useEffect(() => {
    // const storedEmail = localStorage.getItem('userId');
    // const username = storedEmail.split("@")[0];
    // setUsernameid(username);

    // const roomid = localStorage.getItem('roomid');
    // setRoomname(roomid);


    //payment start
    const paymentStatus = localStorage.getItem('paymentStatus');
    if (paymentStatus === 'true') {
      setIsPaymentDone(true);
    }
    //payment end

    

    // api call
const fetchEmojis = async () => {
  try {
    setLoading(true);
    const storeProgramInfo = localStorage.getItem("ProgramInfo")
    let category = storeProgramInfo;
    // Remove any surrounding quotes from the category string, if present
    if (typeof category === 'string') {
      category = category.replace(/^"|"$/g, '');  // Removes leading and trailing quotes
    }

    console.log("sahu2new",category)
    setProgramInfo(storeProgramInfo)
    const response = await axios.get(
      `https://watchparty.tpvai.com/b/api/emoji/search?category=${category}`
    );
    setEmojis(response.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching emojis", error);
    setLoading(false);
  }
};
    fetchEmojis();
    
    const handleKeyPress = (e) => {
      if (e.key === "7") {
        setCurrentAiPage(1)
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };

  }, []);


  useEffect(() => {
    // Event handler for global state changes
    const handleGlobalStateChange = (event) => {
      setGlobalValue(event.detail); // Update the React state with the new global state value
      setPlayingIndex(event.detail);
      console.log("from andio js file",event.detail)

    };

    // Listen for the custom event
    window.addEventListener("globalStateChanged", handleGlobalStateChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("globalStateChanged", handleGlobalStateChange);
    };
  }, []);



  

    //new changes ui
    const handleNext = useCallback(() => {
      if (startIndex + imagesPerPage < emojis.length) {
        setStartIndex((prevIndex) => prevIndex + imagesPerPage);
      }
    }, [startIndex, emojis.length]);
  
    const handlePrevious = useCallback(() => {
      if (startIndex - imagesPerPage >= 0) {
        setStartIndex((prevIndex) => prevIndex - imagesPerPage);
      }
    }, [startIndex]);
  
    const handleImageClick = (index,emoji) => {
      setClickedImage(index + 1);
      console.log("emojis-new-audio-4",emoji.url)
      const url =  `https://watchparty.tpvai.com/b${emoji.url}`
      if (emoji.paid && !isPaymentDone) {
        handlePaymentConfirmation();
      } else {
        sendMessage(url)
      }
    };
  
  console.log("anu recording",recording)
    useEffect(() => {
      const handleKeyPress = (event) => {
        const key = event.key;
  
        if (key >= "1" && key <= "6") {
          const index = parseInt(key) - 1;
          if (emojiRefs.current[index]) {
            console.log("emojis-new-audio-2",clickedImage)
            emojiRefs.current[index].click();
          }
        // } else if (key === "ArrowRight") {
        } else if (key === "0") {
          handleNext();
        } 
        // else if (key === "9") {
        //   console.log("anu 9")
        //   recording ? stopRecording() : startRecording();
        // }
        else if (key === "8") {
          handlePrevious();
        }
      };
  
      window.addEventListener("keydown", handleKeyPress);
  
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [handleNext, handlePrevious]);
  

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === "9") {
          if (recording) {
            stopRecording();
          } else {
            startRecording();
          }
        }
      };
  
      window.addEventListener("keydown", handleKeyDown);
  
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [recording]);


  
    console.log("emojis-new-audio-3",clickedImage)
  

  const currentEmojis = emojis.slice(startIndex, startIndex + imagesPerPage);


  const handlePaymentConfirmation = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Payment Confirmation',
      html: `
        <div style="text-align: center;">
          <label for="swal-input1">Amount: <strong>$30</strong></label>
        </div>
        <input id="swal-input1" class="swal2-input" placeholder="Enter your card number" type="text" 
               maxlength="16" pattern="\\d{16}" inputmode="numeric" style="margin-top: 15px;">
        <input id="swal-input3" class="swal2-input" placeholder="MM/YY" type="text" 
               pattern="(0[1-9]|1[0-2])\\d{2}" maxlength="4" style="margin-top: 15px;">
        <input id="swal-input2" class="swal2-input" placeholder="Enter CVV" type="text" 
               maxlength="3" pattern="\\d{3}" inputmode="numeric">
      `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'Pay',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const cardNumber = document.getElementById('swal-input1').value;
        const expiryDate = document.getElementById('swal-input3').value;
        const cvv = document.getElementById('swal-input2').value;
  
        if (!cardNumber || !expiryDate || !cvv) {
          Swal.showValidationMessage('Please enter your card number, expiry date, and CVV');
          return false;
        }
        return { cardNumber, expiryDate, cvv };
      },
      customClass: {
        popup: 'custom-swal-popup',
      },
    });
  
    if (formValues) {
      // Simulate payment success after user submits details
      localStorage.setItem('paymentStatus', 'true');
      setIsPaymentDone(true);
    }
  };
  
  const showFloatingEmoji = (imageUrl) => {
    if (imageUrl.endsWith('.png')) return; //not flying stickers
    if (imageUrl.endsWith('.wav')) return;
    const screenWidth = window.innerWidth;
    const maxOffset = screenWidth / 4;
    const offset = Math.random() * maxOffset * (Math.random() < 0.5 ? -1 : 1);
    const container = document.createElement('div');
    container.className = 'floating-emoji-container';
    container.style.left = `calc(50% + ${offset}px)`;
    container.style.transform = 'translateX(-50%)';
    const floatingEmoji = document.createElement('img');
    floatingEmoji.className = 'floating-emoji';
    floatingEmoji.src = imageUrl;
    floatingEmoji.alt = 'Emoji';
    floatingEmoji.style.width = '60px';
    floatingEmoji.style.height = '60px';
    container.appendChild(floatingEmoji);
    document.body.appendChild(container);
    const expirationTime = Date.now() + 2000;
    setFloatingEmojis([...floatingEmojis, { container, expirationTime }]);

    const cardRect = document.querySelector('.card').getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const emojiRect = floatingEmoji.getBoundingClientRect();

    if (containerRect.bottom > cardRect.top) {
      const newTop = cardRect.top - emojiRect.height - 10;
      container.style.top = `${newTop}px`;
    }
  };

  // ai
//   const handleGenerateImage = async (prompt,audio_status) => {
// //     setIsLoading(true);
//     // try {
//     //   const response = await fetch('https://watchparty.tpvai.com/b/generate-image', {
//     //     method: 'POST',
//     //     headers: {
//     //       'Content-Type': 'application/json',
//     //     },
//     //     body: JSON.stringify({ prompt }),
//     //   });
      
// //       const data = await response.json();
// //       console.log("data-->",data.imageurl)
// //       setGeneratedImage(data.imageurl
// // ); // Assuming the API returns { image: "imageUrl" }
// //     setCurrentAiPage(3); // Navigate to Page5 after successful generation
// //     } catch (error) {
// //       console.error('Error generating image:', error);
// //       alert('Failed to generate image. Please try again.');
// //     } finally {
// //       setIsLoading(false);
// //     }


// setIsLoading(true);
// try {
//   const response = await fetch(`https://watchparty.tpvai.com/b/aiimages/${prompt}`, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     }
//   });
  
//   const data = await response.json();
//   console.log("data-id->",data.imageUrl)
//   setGeneratedImage(data.imageUrl
// ); // Assuming the API returns { image: "imageUrl" }
// setCurrentAiPage(2); // Navigate to Page5 after successful generation
// } catch (error) {
//   console.error('Error generating image:', error);
//   alert('Failed to generate image. Please try again.');
// } finally {
//   setIsLoading(false);
// }




//   };

const handleGenerateImage = async (prompt, audio_status) => {
  setIsLoading(true);

  try {
    let response;
    let data;

    if (audio_status) {
      // If audio_status is true, use POST request
      response = await fetch('https://watchparty.tpvai.com/b/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
    } else {
      // If audio_status is false, use GET request
      response = await fetch(`https://watchparty.tpvai.com/b/aiimages/${prompt}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    data = await response.json();
    if (audio_status) {
      setGeneratedImage(data.imageurl)
    }else{
      setGeneratedImage(data.imageUrl)
    }
    setCurrentAiPage(2);
  } catch (error) {
    console.error('Error generating image:', error);
    alert('Failed to generate image. Please try again.');
  } finally {
    setIsLoading(false);
  }
};


  useEffect(() => {
    if (uploadedURL !== "no audio found") {
      const newIndex = messages.length - 1;
      setCurrentAudioIndex(newIndex);
      // playAudio(newIndex);
    }
  }, [uploadedURL]);
  

  useEffect(() => {
    const handleMessage = (message) => {
      const username = message.from;
      const isWav = message.message.endsWith('.wav');
  
      showFloatingEmoji(message.message);
      setMessages((prevMessages) => [...prevMessages, message]);
      console.log("anu play -1")
      // Automatically play audio if the message is a .wav file
      // if (isWav) {
      //   console.log("anu play 0")
      //   setTimeout(() => {
      //     console.log("anu play 1")
      //     const newIndex = messages.length; // Index of the new message
      //     setCurrentAudioIndex(newIndex); // Store index to control the current audio
      //     // audioRefs.current[newIndex].play(); // Auto-play the new audio
      //     playAudio(newIndex);
      //   }, 100); // Delay to ensure audio ref is set
      // }

      if (isWav) {
        setUploadedURL(message.message);
      }
    };
  
    socket.on("send-message-to-client", handleMessage);
  
    return () => {
      socket.off("send-message-to-client", handleMessage);
    };
  }, [messages, socket]);
  

  

  const sendMessage = (messageToSend) => {
    if (messageToSend) {
      socket.emit("receive-message-from-client", messageToSend);
      setMessageInput('');
    }
  };

  const [currentAiPage, setCurrentAiPage] = useState('main');

  const handleAiNext = () => {
    const currentPageNum = parseInt(currentAiPage);
    if (currentPageNum < 4) setCurrentAiPage(currentPageNum + 1);
  };

  const handleAiPrev = () => {
    const currentPageNum = parseInt(currentAiPage);
    if (currentPageNum > 1) setCurrentAiPage(currentPageNum - 1);
  };

  console.log("currentAiPage->",currentAiPage)

  const renderAiPage = () => {
    // switch(currentAiPage) {
    //   case 1:
    //     return (
    //       <div className="card">
    //         <Page2 setCurrentAiPage={setCurrentAiPage}/>
    //       </div>
    //     );
    //   case 2:
    //     return (
    //       <div className="card">
    //         <Page3 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage}/>
    //       </div>
    //     );
    //   case 3:
    //     return (
    //       <div className="card">
    //         <Page4 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage} />
    //       </div>
    //     );
    //   case 4:
    //     return (
    //       <div className="card">
    //       <Page5 setCurrentAiPage={setCurrentAiPage} generatedImage={generatedImage} isLoading={isLoading} sendMessage={sendMessage}/>
    //       </div>
    //     );
    //   default:
    //     return (
    //       <div className="card">
    //         <div className="container text-center mt-2" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
    //           {loading ? (
    //             <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
    //               <span className="visually-hidden">Loading...</span>
    //             </div>
    //           ) : (
    //             <>
    //               <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
    //                 {currentEmojis.map((emoji, index) => (
    //                   <div
    //                     key={index}
    //                     className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
    //                     ref={(el) => (emojiRefs.current[index] = el)}
    //                     onClick={() => handleImageClick(index, emoji)}
    //                     style={{ margin: 0, padding: 0 }}
    //                   >
    //                     {emoji.paid && (
    //                       <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
    //                         P
    //                       </span>
    //                     )}
    //                     <img
    //                       src={`https://watchparty.tpvai.com/b${emoji.url}`}
    //                       alt="emoji"
    //                       className="img-fluid"
    //                       style={{
    //                         width: "23px",
    //                         height: "23px",
    //                         objectFit: "cover",
    //                       }}
    //                     />
    //                     <span className="">{index + 1}</span>
    //                   </div>
    //                 ))}
    //               </div>



    //               <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
    //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
    //                   <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
    //                     <img
    //                       src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
    //                       alt="previous"
    //                       className="img-fluid"
    //                       style={{
    //                         width: "23px",
    //                         height: "23px",
    //                         objectFit: "cover",
    //                       }}
    //                     />
    //                     <p className="mt-2">8</p>
    //                   </div>
    //                 </div>
    //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
    //                   <div style={{ cursor: "pointer" }}>

    //                     <div 
    //                           tabIndex={0}  // Make the div focusable
    //                           onClick={recording ? stopRecording : startRecording} 
    //                           role="button"  // Add role for better accessibility
    //                           style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
    //                         >
    //                           {recording ? (
    //                             <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
    //                           ) : (
    //                             <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
    //                           )}
    //                           <p className="mt-2">9</p>
    //                         </div>

    //                   </div>
    //                 </div>
    //                 <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
    //                   <div style={{ cursor: "pointer" }} onClick={handleNext}>
    //                     <img
    //                       src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
    //                       alt="next"
    //                       className="img-fluid"
    //                       style={{
    //                         width: "23px",
    //                         height: "23px",
    //                         objectFit: "cover",
    //                       }}
    //                     />
    //                     <p className="mt-2">0</p>
    //                   </div>
    //                 </div>
    //                 <div className="text-center">
    //           <button style={{background: 'linear-gradient(to right, rgb(0, 201, 255), rgb(146, 140, 229))', border: 'none', borderRadius: '25px', color: 'white', fontSize: '14px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px', cursor: 'pointer'}}>AI Generate</button>
    //           <div style={styles.spanContainer}>
    //             <span  style={styles.span}>7</span>
    //           </div>
    //         </div>
    //               </div>
    //             </>
    //           )}
    //         </div>

    //       </div>
    //     );
    // }

    switch(currentAiPage) {
      case 1:
        return (
          <div className="card">
            <Page3 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage}/>
          </div>
        );
      case 3:
        return (
          <div className="card">
            <Page4 setCurrentAiPage={setCurrentAiPage} onGenerate={handleGenerateImage} />
          </div>
        );
      case 2:
        return (
          <div className="card">
          <Page5 setCurrentAiPage={setCurrentAiPage} generatedImage={generatedImage} isLoading={isLoading} sendMessage={sendMessage} currentAiPage={currentAiPage}/>
          </div>
        );
      default:
        return (
          <div className="card">
            <div className="container text-center mt-2" id="emoji-gallery" tabIndex="0" style={{ margin: 0, padding: 0 }}>
              {loading ? (
                <div className="spinner-border" role="status" style={{margin: '27px 82px'}}>
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
                    {currentEmojis.map((emoji, index) => (
                      <div
                        key={index}
                        className="col-4 d-flex flex-column align-items-center position-relative px-1" // Added px-1 for spacing
                        ref={(el) => (emojiRefs.current[index] = el)}
                        onClick={() => handleImageClick(index, emoji)}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {emoji.paid && (
                          <span style={{position: 'absolute', backgroundColor: '#dfb400', color: '#000', width: '21px', height: '21px', borderRadius: '50%', display: 'inline-block', textAlign: 'center', marginLeft:'47px'}}>
                            P
                          </span>
                        )}
                        <img
                          src={`https://watchparty.tpvai.com/b${emoji.url}`}
                          alt="emoji"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <span className="circle-button-remote">{index + 1}</span>
                      </div>
                    ))}
                  </div>



                  <div className="row no-gutters" style={{ margin: 0, padding: 0 }}>
                    <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }} onClick={handlePrevious}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/608/608283.png"
                          alt="previous"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <p className="mt-2"><span className="circle-button-remote">8</span></p>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center px-1" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }}>

                        <div 
                              tabIndex={0}  // Make the div focusable
                              onClick={recording ? stopRecording : startRecording} 
                              role="button"  // Add role for better accessibility
                              style={{ cursor: 'pointer' }}  // Add pointer cursor to indicate clickability
                            >
                              {recording ? (
                                <img src="https://watchparty.tpvai.com/b/uploads/stop.gif" alt="Stop Recording" />
                              ) : (
                                <img src="https://watchparty.tpvai.com/b/uploads/start.png" alt="Start Recording" />
                              )}
                              <p className="mt-2"><span className="circle-button-remote">9</span></p>
                            </div>

                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center" style={{ margin: 0, padding: 0 }}>
                      <div style={{ cursor: "pointer" }} onClick={handleNext}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/626/626043.png"
                          alt="next"
                          className="img-fluid"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "cover",
                          }}
                        />
                        <p className="mt-2"><span className="circle-button-remote">0</span></p>
                      </div>
                    </div>
                    <div className="text-center">
              <button style={{background: 'linear-gradient(to right, rgb(0, 201, 255), rgb(146, 140, 229))', border: 'none', borderRadius: '25px', color: 'white', fontSize: '14px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px', cursor: 'pointer'}}>AI Generate</button>
              <div style={styles.spanContainer}>
                {/* <span  style={styles.span}>7</span> */}
                <p ><span className="circle-button-remote">7</span></p>
              </div>
            </div>
                  </div>
                </>
              )}
            </div>

          </div>
        );
    }
  };

  return (
    <>
      <div className="container-fluid">
      <div style={{textAlign:'center'}}>
      <AdBanner/>
      </div>
        <div>
          <div className="col-6 chatitem">
              {messages.map((msg, index) => {
                const userName = getStringBeforeAtSymbol(extractLastPathPart(msg.from));
                const alignStyle = CurrentUser === extractLastPathPart(msg.from) ? 'end' : 'left';
                const marginRStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const marginTStyle = getStringBeforeAtSymbol(CurrentUser) === userName ? '15px' : '0px';
                const isGif = msg.message.endsWith('.gif');
                const isPng = msg.message.endsWith('.png');
                const isWav = msg.message.endsWith('.wav');

                if (isGif) {
                  return null;
                }
                if (isPng) {
                  return (
                    <div key={index} style={{ textAlign: alignStyle, marginRight: marginRStyle , padding:'10px 0' }}>
                      <img src={`https://watchparty.tpvai.com/b${msg.message}`} alt="png" width="75" height="75" />
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
                
                // if (isWav) {
                //   return (
                //     <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
                //        {/* <audio controls ref={(el) => (audioRefs.current[index] = el)} src={msg.message} /> */}
                //        <audio
                //         controls
                //         ref={(el) => (audioRefs.current[index] = el)} // Store the ref to each audio element
                //         src={msg.message}
                //         onPlay={() => playAudio(index)} // Trigger playAudio when playing
                //         onPause={() => stopAudio(index)} // Trigger stopAudio when pausing
                //       />
                //       <span className="autherusername">{userName}</span>
                //     </div>
                //   );
                // }
                if (isWav) {
                  return (
                    <div key={index} onClick={() => setCurrentAudioIndex(index)} style={{ textAlign: 'left' }}>
                      {/* The audio element will be hidden but still present for functionality */}
                      <audio
                        ref={(el) => (audioRefs.current[index] = el)} // Store the ref to each audio element
                        src={msg.message}
                        className="d-none" // Hide the audio element
                        onEnded={() => setPlayingIndex(null)} // Reset on audio end
                      />
                
                      {/* Play/Pause button */}
                      <button
                        onClick={() => togglePlayPause(index)} // Toggle play/pause when clicked
                        className="btn btn-primary rounded-circle p-3"
                      >
                        {playingIndex === index ? <Pause size={20} /> : <Play size={20} />}
                      </button>
                      
                      <span className="autherusername">{userName}</span>
                    </div>
                  );
                }
                
              })}

          </div>
        </div>
        <div className="row">
          <div className="col-6 text-sm-end">
          </div>
        </div>
      </div>

      <footer className="fixed-bottom p-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-auto">
              {renderAiPage()}
              {/* {currentAiPage !== 'main' && (
                <div className="navigation-buttons">
                  <button 
                    onClick={handleAiPrev} 
                    disabled={currentAiPage === 1}
                    className="nav-btn"
                  >
                    Previous
                  </button>
                  <span className="page-indicator">{currentAiPage} / 4</span>
                  <button 
                    onClick={handleAiNext} 
                    disabled={currentAiPage === 4}
                    className="nav-btn"
                  >
                    Next
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};


export default ChatScreen;


