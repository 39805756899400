// Loader.js
import React from 'react';
import './Loader.css';

const Loader = () => {
    return (

/* From Uiverse.io by omar-alghaish */ 
/* From Uiverse.io by Bodyhc */ 
<div class="custom-loader"></div>
    );
};

export default Loader;
