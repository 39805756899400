import React from 'react';
import UserAvatar from './UserAvatar';

function ChatMessage({ message, user }) {
  return (
    <div className="chat-message">
      <UserAvatar user={user} size="sm" />
      <div className="chat-message-text">{message.text}</div>
    </div>
  );
}

export default ChatMessage;
