// import React, { useRef, useEffect, useState } from "react";

// const Page4 = ({ setCurrentAiPage , onGenerate }) => {
//   const textareaRef = useRef(null);
//   const Cpage = 4;
//   const [transcript, setTranscript] = useState('hello');
//   const [isListening, setIsListening] = useState(false);
//   const [lang, setLang] = useState("en-US");
//   const [error, setError] = useState(null);
//   let recognition;

//   useEffect(() => {
//     if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
//       setError('Speech recognition is not supported in this browser.');
//       return;
//     }

//     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//     recognition = new SpeechRecognition();
//     recognition.continuous = true;
//     recognition.interimResults = true;
//     recognition.lang = lang;

//     recognition.onresult = (event) => {
//       let finalTranscript = '';
//       for (let i = event.resultIndex; i < event.results.length; i++) {
//         finalTranscript += event.results[i][0].transcript;
//       }
//       setTranscript(finalTranscript);
//     };

//     recognition.onerror = (event) => {
//       setError(`Error occurred in speech recognition: ${event.error}`);
//     };

//     if (isListening) recognition.start();
//     else recognition.stop();

//     return () => recognition && recognition.stop();
//   }, [isListening]);

//   const handleStart = () => {
//     setError(null);
//     setIsListening(true);
//   };

//   const handleStop = () => {
//     setIsListening(false);
//   };

//   const handleBackClick = () => {
//     console.log("Back button clicked");
//     setCurrentAiPage("main");
//   };

// //   const handleGenerateClick = () => {
// //     console.log("Generate button clicked");
// //     setPage(5);
// //   };
// const handleGenerateClick = () => {
//     if (transcript.trim()) {
//       onGenerate(transcript);
//       // setCurrentAiPage(4);
//     } else {
//       alert('Please record some text first');
//     }
//   };

//   const handleInput = () => {
//     textareaRef.current.style.height = "auto";
//     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "3") {
//         if (isListening) {
//           handleStop();
//         } else {
//           handleStart();
//         }
//       } else if (e.key === "5") {
//         handleBackClick();
//       }
//       else if (e.key === "2") {
//         handleGenerateClick();
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);

//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [isListening]);

//   return (
//     <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px", fontFamily: "Arial, sans-serif" }}>
//       <div style={{
//         position: "absolute",
//         top: "10px",
//         left: "10px",
//         cursor: "pointer",
//         fontSize: "14px",
//         color: "#007bff",
//         display: "flex",
//         alignItems: "center",
//         transition: "color 0.2s"
//       }} onClick={handleBackClick}
//         onMouseOver={(e) => (e.target.style.color = "#0056b3")}
//         onMouseOut={(e) => (e.target.style.color = "#007bff")}>
//         Home
//         <p style={{
//           margin: "0",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#333",
//           marginLeft: "5px"
//         }}>5</p>
//       </div>

//       <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "40px" }}>
//         <button
//           onClick={isListening ? handleStop : handleStart}
//           style={{
//             padding: '10px 20px',
//             fontSize: '16px',
//             backgroundColor: isListening ? '#dc3545' : '#007bff',
//             color: '#fff',
//             border: 'none',
//             borderRadius: '5px',
//             cursor: 'pointer',
//             marginBottom: '15px',
//             transition: "background-color 0.3s, transform 0.2s"
//           }}
//           onMouseOver={(e) => e.target.style.transform = "scale(1.05)"}
//           onMouseOut={(e) => e.target.style.transform = "scale(1)"}
//         >
//           {isListening ? 'Stop Recording' : 'Start Recording'}
//         </button>
//         <p style={{ margin: "5px 0 0 0", fontSize: "14px", textAlign: "center" }}>Record Start/Stop</p>
//         <p style={{ margin: "0", fontSize: "12px", textAlign: "center" }}>3</p>
//       </div>

//       <div style={{ marginTop: "20px", width: "80%", textAlign: "center" }}>
//         {error && <p style={{ color: 'red' }}>{error}</p>}
//         <textarea
//           ref={textareaRef}
//           placeholder="Record Your Prompts"
//           onInput={handleInput}
//           value={transcript}
//           style={{
//             width: "100%",
//             minHeight: "90px",
//             textAlign: "center",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "16px",
//             padding: "10px",
//             resize: "none",
//             overflow: "hidden",
//             transition: "border-color 0.3s"
//           }}
//         />
//       </div>

//       <div style={{ marginTop: "20px" }}>
//         <button
//           onClick={handleGenerateClick}
//           style={{
//             padding: "10px 20px",
//             border: "2px solid #007bff",
//             borderRadius: "8px",
//             fontSize: "14px",
//             backgroundColor: "#007bff",
//             color: "white",
//             cursor: "pointer",
//             transition: "background-color 0.3s, transform 0.2s"
//           }}
//           onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
//           onMouseOut={(e) => e.target.style.backgroundColor = "#007bff"}
//         >
//           Generate
//         </button>
//         <p style={{ marginTop: "5px", fontSize: "12px", textAlign: "center" }}>2</p>
//       </div>
//     </div>
//   );
// };

// export default Page4;


//ui fixed
// import React, { useRef, useEffect, useState } from "react";

// const Page4 = ({ setCurrentAiPage, onGenerate }) => {
//   const textareaRef = useRef(null);
//   const [transcript, setTranscript] = useState("");
//   const [isListening, setIsListening] = useState(false);
//   const [lang, setLang] = useState("en-US");
//   const [error, setError] = useState(null);
//   let recognition;

//   useEffect(() => {
//     if (!("SpeechRecognition" in window || "webkitSpeechRecognition" in window)) {
//       setError("Speech recognition is not supported in this browser.");
//       return;
//     }

//     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//     recognition = new SpeechRecognition();
//     recognition.continuous = true;
//     recognition.interimResults = true;
//     recognition.lang = lang;

//     recognition.onresult = (event) => {
//       let finalTranscript = "";
//       for (let i = event.resultIndex; i < event.results.length; i++) {
//         finalTranscript += event.results[i][0].transcript;
//       }
//       setTranscript(finalTranscript);
//     };

//     recognition.onerror = (event) => {
//       setError(`Error occurred in speech recognition: ${event.error}`);
//     };

//     if (isListening) recognition.start();
//     else recognition.stop();

//     return () => recognition && recognition.stop();
//   }, [isListening]);

//   const handleStart = () => {
//     setError(null);
//     setIsListening(true);
//   };

//   const handleStop = () => {
//     setIsListening(false);
//   };

//   const handleBackClick = () => {
//     setCurrentAiPage("main");
//   };

//   const handleGenerateClick = () => {
//     if (transcript.trim()) {
//       onGenerate(transcript);
//     } else {
//       alert("Please record some text first");
//     }
//   };

//   const handleInput = () => {
//     textareaRef.current.style.height = "auto";
//     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       if (e.key === "3") {
//         isListening ? handleStop() : handleStart();
//       } else if (e.key === "5") {
//         handleBackClick();
//       } else if (e.key === "2") {
//         handleGenerateClick();
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => window.removeEventListener("keydown", handleKeyPress);
//   }, [isListening]);

//   return (
//     <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px",padding:'3px' }}>
//       <div
//         style={{ position: "absolute", top: "10px", left: "10px", cursor: "pointer", fontSize: "14px", color: "#007bff" }}
//         onClick={handleBackClick}
//       >
//         Home <span style={{ marginLeft: "5px", fontSize: "12px", color: "#333" }}>5</span>
//       </div>

//       <button
//         onClick={isListening ? handleStop : handleStart}
//         style={{
//           padding: "8px 16px",
//           fontSize: "16px",
//           backgroundColor: isListening ? "#dc3545" : "#007bff",
//           color: "#fff",
//           border: "none",
//           borderRadius: "5px",
//           cursor: "pointer",
//           margin: "10px 0",
//           transition: "background-color 0.3s",
//         }}
//       >
//         {isListening ? "Stop Recording" : "Start Recording"} (3)
//       </button>

//       <div style={{ marginTop: "15px", width: "90%", textAlign: "center" }}>
//         {error && <p style={{ color: "red" }}>{error}</p>}
//         <textarea
//           ref={textareaRef}
//           placeholder="Record Your Prompts"
//           onInput={handleInput}
//           value={transcript}
//           style={{
//             width: "100%",
//             minHeight: "60px",
//             textAlign: "center",
//             border: "1px solid #007bff",
//             borderRadius: "5px",
//             fontSize: "14px",
//             padding: "8px",
//             resize: "none",
//             overflow: "hidden",
//           }}
//         />
//       </div>

//       <button
//         onClick={handleGenerateClick}
//         style={{
//           marginTop: "15px",
//           padding: "8px 16px",
//           border: "none",
//           borderRadius: "5px",
//           fontSize: "14px",
//           backgroundColor: "#007bff",
//           color: "white",
//           cursor: "pointer",
//           transition: "background-color 0.3s",
//         }}
//       >
//         Generate (2)
//       </button>
//     </div>
//   );
// };

// export default Page4;


//laoder
import React, { useRef, useEffect, useState } from "react";
import { Undo2 } from 'lucide-react';
const Loader = () => (
  <div
    style={{
      display: "inline-block",
      border: "3px solid #f3f3f3",
      borderTop: "4px solid #007bff",
      borderRadius: "50%",
      width: "16px", // Adjust to fit within the button
      height: "16px", // Adjust to fit within the button
      animation: "spin 1s linear infinite",
    }}
  >
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

const Page4 = ({ setCurrentAiPage, onGenerate , isLoading}) => {
  const textareaRef = useRef(null);
  const [transcript, setTranscript] = useState("Cute Dog Playing with toys");
  const [isListening, setIsListening] = useState(false);
  const [lang, setLang] = useState("en-US");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let recognition;

  useEffect(() => {
    if (!("SpeechRecognition" in window || "webkitSpeechRecognition" in window)) {
      setError("Speech recognition is not supported in this browser.");
      return;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = lang;

    recognition.onresult = (event) => {
      let finalTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        finalTranscript += event.results[i][0].transcript;
      }
      setTranscript(finalTranscript);
    };

    recognition.onerror = (event) => {
      setError(`Error occurred in speech recognition: ${event.error}`);
    };

    if (isListening) recognition.start();
    else recognition.stop();

    return () => recognition && recognition.stop();
  }, [isListening]);

  const handleStart = () => {
    setError(null);
    setIsListening(true);
  };

  const handleStop = () => {
    setIsListening(false);
  };

  const handleBackClick = () => {
    setCurrentAiPage(1);
  };

  const handleGenerateClick = () => {
    if (transcript.trim()) {
      setLoading(true); // Set loading to true
      console.log("transcript-",transcript)
      const audio_status = true
      onGenerate(transcript,audio_status);
    } else {
      alert("Please record some text first");
    }
  };

  const handleInput = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "3") {
        isListening ? handleStop() : handleStart();
      } else if (e.key === "5") {
        handleBackClick();
      } else if (e.key === "2") {
        handleGenerateClick();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isListening]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px", padding: "3px" }}>
      <div
        style={{ position: "absolute", top: "10px", left: "10px", cursor: "pointer", fontSize: "14px", color: "#007bff" }}
        onClick={handleBackClick}
      >
        {/* Home <span style={{ marginLeft: "5px", fontSize: "12px", color: "#333" }}>5</span> */}
        <p  style={{
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
        }}> <Undo2 size={15}/><span className="circle-button-remote">5</span></p>
      </div>

      <button
        onClick={isListening ? handleStop : handleStart}
        disabled={loading} // Disable when loading
        style={{
          padding: "8px 16px",
          fontSize: "16px",
          backgroundColor: isListening ? "#dc3545" : "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          margin: "10px 0",
          transition: "background-color 0.3s",
        }}
      >
        {isListening ? "Stop Recording" : "Start Recording"} 7
      </button>

      <div style={{ marginTop: "15px", width: "90%", textAlign: "center" }}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <textarea
          ref={textareaRef}
          placeholder="Record Your Prompts"
          onInput={handleInput}
          value={transcript}
          style={{
            width: "100%",
            minHeight: "60px",
            textAlign: "center",
            border: "1px solid #007bff",
            borderRadius: "5px",
            fontSize: "14px",
            padding: "8px",
            resize: "none",
            overflow: "hidden",
          }}
        />
      </div>

      <button
        onClick={handleGenerateClick}
        disabled={loading} // Disable when loading
        style={{
          marginTop: "15px",
          padding: "8px 16px",
          border: "none",
          borderRadius: "5px",
          fontSize: "14px",
          backgroundColor: "#007bff",
          color: "white",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
      >
        {/* {loading ? "Loading..." : "Generate (2)"} */}
        {loading ? <Loader /> : "Generate 2"}
      </button>
    </div>
  );
};

export default Page4;
