// import React, { useEffect } from 'react';

// const Page3 = ({ setCurrentAiPage , onGenerate , isLoading }) => {
//   // const navigate = useNavigate();
//   const Cpage = 3
//   const handleBackClick = () => {
//     console.log("Back button clicked");
//     setCurrentAiPage("main");
//   };

//   const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     let category = storeProgramInfo;

//   const handleGenerateClick = () => {
//     console.log("Generate button clicked");
//     setCurrentAiPage(4);
//     // const ownprompt = "Create a set of stickers featuring a cute, expressive cartoon cat character in different emotions: happy, sad, excited, and surprised. Each sticker should have bold outlines, vibrant colors, and playful details. The background should be transparent, so only the character is visible."
//     // onGenerate(category);
//     const ownprompt = `${category} Create a set of stickers.The background should be transparent, so only the character is visible.`;
//     onGenerate(ownprompt);

//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       // Check if the key pressed is '1' or '2' and call corresponding function
//       if (e.key === "1") {
//         handleGenerateClick();
//       }
//       else if (e.key === "5") {
//         handleBackClick();
//       }
//     };

//     // Add the event listener on component mount
//     window.addEventListener("keydown", handleKeyPress);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []);

//   return (
//     <div style={{
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       marginTop: "20px",
//       fontFamily: "Arial, sans-serif",
//       color: "#333",
//       padding:'13px'

//     }}>
//       {/* Back button at the top left */}
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//           transition: "color 0.2s",
          
//         }}
//         onClick={handleBackClick}
//         onMouseOver={(e) => (e.target.style.color = "#0056b3")}
//         onMouseOut={(e) => (e.target.style.color = "#007bff")}
//       >
//         {/* <FaArrowLeft size={20} style={{ marginRight: "5px" }} /> */}
//         Home
//         <p style={{
//           margin: "0",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#333",
//           marginLeft: "5px",
//         }}>5</p>
//       </div>

//       {/* Generate button in the center */}
//       <div style={{ marginTop: "100px", textAlign: "center" }}>
//       <button
//           onClick={handleGenerateClick}
//           style={{
//             padding: "10px 40px",
//             border: "none",
//             borderRadius: "8px",
//             fontSize: "16px",
//             fontWeight: "bold",
//             backgroundColor: "#007bff",  // Consistent blue background
//             color: "white",
//             cursor: "pointer",
//             transition: "transform 0.2s", // Only apply scaling transition, not color
//           }}
//           onMouseOver={(e) => {
//             e.target.style.transform = "scale(1.05)";
//           }}
//           onMouseOut={(e) => {
//             e.target.style.transform = "scale(1)";
//           }}
//         >
//           Generate
//         </button>
//         <p style={{ marginTop: "5px", fontSize: "14px", color: "#555" }}>1</p>
//       </div>
//     </div>
//   );
// };

// export default Page3;



//new changes
// import React, { useEffect } from 'react';


// const Page3 = ({ setCurrentAiPage , onGenerate , isLoading }) => {
//   // const navigate = useNavigate();
//   const Cpage = 3
//   const handleBackClick = () => {
//     console.log("Back button clicked");
//     setCurrentAiPage("main");
//   };

//   const storeProgramInfo = localStorage.getItem("ProgramInfo")
//     let category = storeProgramInfo;

//   const handleGenerateClick = () => {
//     console.log("Generate button clicked");
//     setCurrentAiPage(3);
//     const ownprompt = "Create a set of stickers for movie.The background should be transparent, so only the character is visible."
//     onGenerate(ownprompt);

//   };

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       // Check if the key pressed is '1' or '2' and call corresponding function
//       if (e.key === "1") {
//         handleGenerateClick();
//       }
//       else if (e.key === "5") {
//         handleBackClick();
//       }
//     };

//     // Add the event listener on component mount
//     window.addEventListener("keydown", handleKeyPress);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []);

//   return (
//     <div style={{
//       display: "flex",
//       flexDirection: "column",
//       alignItems: "center",
//       marginTop: "20px",
//       fontFamily: "Arial, sans-serif",
//       color: "#333",
//       padding:'13px'

//     }}>
//       {/* Back button at the top left */}
//       <div
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//           transition: "color 0.2s",
          
//         }}
//         onClick={handleBackClick}
//         onMouseOver={(e) => (e.target.style.color = "#0056b3")}
//         onMouseOut={(e) => (e.target.style.color = "#007bff")}
//       >
//         {/* <FaArrowLeft size={20} style={{ marginRight: "5px" }} /> */}
//         Home
//         <p style={{
//           margin: "0",
//           fontSize: "12px",
//           textAlign: "center",
//           color: "#333",
//           marginLeft: "5px",
//         }}>5</p>
//       </div>

//       {/* Generate button in the center */}
//       <div style={{ marginTop: "100px", textAlign: "center" }}>
      // <button
      //     onClick={handleGenerateClick}
      //     disabled={isLoading}
      //     style={{
      //       padding: "10px 40px",
      //       border: "none",
      //       borderRadius: "8px",
      //       fontSize: "16px",
      //       fontWeight: "bold",
      //       backgroundColor: "#007bff",  // Consistent blue background
      //       color: "white",
      //       cursor: "pointer",
      //       transition: "transform 0.2s", // Only apply scaling transition, not color
      //     }}
      //     onMouseOver={(e) => {
      //       e.target.style.transform = "scale(1.05)";
      //     }}
      //     onMouseOut={(e) => {
      //       e.target.style.transform = "scale(1)";
      //     }}
      //   >
      //     {/* Generate */}
      //     {isLoading ? "Loading..." : "Generate (1)"}
      //   </button>
//         {/* <p style={{ marginTop: "5px", fontSize: "14px", color: "#555" }}>1</p> */}
//       </div>
//     </div>
//   );
// };

// export default Page3;



// new changes - 25/11/24
// import React, { useState, useEffect } from 'react';

// function PromptDropdown() {
//   const [selectedPrompt, setSelectedPrompt] = useState('Select a prompt');

//   // Define five prompts that correspond to keypresses '1' through '5'
//   const prompts = [
//     { key: '1', value: 'First Prompt' },
//     { key: '2', value: 'Second Prompt' },
//     { key: '3', value: 'Third Prompt' },
//     { key: '4', value: 'Fourth Prompt' },
//     { key: '5', value: 'Fifth Prompt' },
//   ];

//   // Effect to listen for keypress events and update selected prompt
//   useEffect(() => {
//     const handleKeyPress = (event) => {
//       const prompt = prompts.find((p) => p.key === event.key);
//       if (prompt) {
//         setSelectedPrompt(prompt.value);
//       }
//     };

//     // Add keydown event listener
//     window.addEventListener('keydown', handleKeyPress);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [prompts]);

//   return (
//     <div className="container d-flex flex-column align-items-center mt-5" style={{ maxWidth: '300px' }}>
//       <h4 className="mb-3 text-center">Selected Prompt</h4>

//       {/* Displaying the selected prompt */}
//       <div
//         className="d-flex align-items-center justify-content-center bg-light border rounded p-3 w-100 text-truncate"
//         style={{ maxWidth: '300px', whiteSpace: 'nowrap' }}
//       >
//         {selectedPrompt}
//       </div>

//       {/* Display all prompts as clickable items */}
//       <div className="mt-4 w-100">
//         {prompts.map((prompt) => (
//           <div
//             key={prompt.key}
//             className="mb-2 d-flex align-items-center justify-content-between bg-light border rounded p-2 w-100 text-truncate"
//             style={{ whiteSpace: 'nowrap' }}
//             onClick={() => setSelectedPrompt(prompt.value)}
//           >
//             {prompt.value}
//           </div>
//         ))}
//       </div>

//       <p className="mt-3 text-muted text-center" style={{ fontSize: '0.9rem' }}>
//         Press keys 1, 2, 3, 4, or 5 to select a prompt directly
//       </p>
//     </div>
//   );
// }

// export default PromptDropdown;



// hfdjfhdj
// import React, { useState } from 'react';

// const Page3 = () => {
//   const [selectedCard, setSelectedCard] = useState(null);

//   const cardTexts = [
//     "cat playing cricket",
//     "Dogs Playing with toys",
//     "monkey playing",
//     "cats playing football",
//     "playing"
//   ];

//   const handleKeyPress = (event) => {
//     const cardIndex = parseInt(event.key) - 1;
//     if (cardIndex >= 0 && cardIndex < cardTexts.length) {
//       setSelectedCard(cardIndex);
//     }
//   };

//   return (
//     <div onKeyDown={handleKeyPress} tabIndex={0} className="container-fluid p-0">
//       <p className="mb-3">Home</p>
//       <div className="d-flex flex-column">
//         {cardTexts.map((cardText, index) => (
//           <div
//             key={index}
//             onClick={() => setSelectedCard(index)}
//             className={`card ${selectedCard === index ? 'bg-primary text-white' : 'bg-light'}`}
//             style={{
//               cursor: 'pointer',
//               marginBottom: '5px',
//               padding: '5px 8px',
//               width: '150px',        
//               height: '25px',        
//               textAlign: 'center',
//               fontSize: '10px',      
//               lineHeight: '1.2',
//               overflow: 'hidden',    
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center'   
//             }}
//           >
//             {cardText}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Page3;



///test
// import React, { useState, useEffect } from 'react';

// const Page3 = ({setCurrentAiPage,onGenerate}) => {
//   const [selectedCard, setSelectedCard] = useState(null);


//      const handleBackClick = () => {
//      console.log("Back button clicked");
//      setCurrentAiPage("main");
//   };



//   // const cardTexts = [
//   //   "cat playing cricket",
//   //   "Dogs Playing with toys",
//   //   "monkey playing",
//   //   "cats playing football",
//   //   "playing"
//   // ];

//   const cardTexts = [
//     { _id: "673dd99deece13c7de7105ef", text: "cat playing cricket" },
//     { _id: "673eee07eece13c7de7106cf", text: "Dogs Playing with toys" },
//     { _id: "673ed480eece13c7de71069d", text: "monkey playing" },
//     { _id: "673dc73c826a5bbe390d54de", text: "cats playing football" },
//     { _id: "673ed3caeece13c7de710699", text: "playing" }
//   ];
  

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       const cardIndex = parseInt(e.key) - 1; // Convert key to index (key '1' -> index 0)
//       if (cardIndex >= 0 && cardIndex < cardTexts.length) {
//         setSelectedCard(cardIndex); // Select the card based on key press
//       }  else if (e.key === "6") {
//         handleBackClick();
//          }
//     };

//     // Add event listener for keydown
//     window.addEventListener("keydown", handleKeyPress);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []); 

//   return (
//     <div className="container-fluid">
//       <p className="mb-3">Home<span >(6)</span></p>
//       <div className="d-flex flex-column">
//         {cardTexts.map((cardText, index) => (
//           <div
//             key={index}
//             onClick={() => setSelectedCard(index)} // Click to select the card
//             className={`card ${selectedCard === index ? 'bg-primary text-white' : 'bg-light'}`}
//             style={{
//               cursor: 'pointer',
//               marginBottom: '5px',
//               padding: '5px 8px',
//               width: '150px',
//               height: '26px',  // Adjust card height to fit button
//               textAlign: 'center',
//               fontSize: '10px',
//               lineHeight: '1.2',
//               overflow: 'hidden',
//               display: 'flex',
//               flexDirection: 'column',  // Allow button to be placed under card text
//               justifyContent: 'center',
//               alignItems: 'center'
//             }}
//           >
//             {cardText.text}({index + 1}) 
            
//             {/* <span style={{ fontSize: '12px', marginLeft: '5px' }}>({index + 1})</span> */}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Page3;


// final one
// import React, { useState, useEffect } from 'react';
// import { AudioWaveform,Undo2 } from 'lucide-react';

// const Page3 = ({ setCurrentAiPage, onGenerate }) => {
//   const [selectedCard, setSelectedCard] = useState(null);

//   const handleBackClick = () => {
//     console.log("Back button clicked");
//     setCurrentAiPage("main");
//   };

//   const cardTexts = [
//     { _id: "673dd99deece13c7de7105ef", text: "cat playing cricket" },
//     { _id: "673eee07eece13c7de7106cf", text: "Dogs Playing with toys" },
//     { _id: "673ed480eece13c7de71069d", text: "monkey playing" },
//     { _id: "673dc73c826a5bbe390d54de", text: "cats playing football" },
//     // { _id: "673ed3caeece13c7de710699", text: "playing" }
//   ];

//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       const cardIndex = parseInt(e.key) - 1; // Convert key to index (key '1' -> index 0)
//       if (cardIndex >= 0 && cardIndex < cardTexts.length) {
//         setSelectedCard(cardIndex); // Select the card based on key press
//         const audio_status = false
//         onGenerate(cardTexts[cardIndex]._id,audio_status); // Trigger onGenerate with the card's _id
//       } else if (e.key === "6") {
//         handleBackClick();
//       }
//       else if (e.key === "7") {
//         setCurrentAiPage(3);
//       }
//     };

//     // Add event listener for keydown
//     window.addEventListener("keydown", handleKeyPress);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [cardTexts, onGenerate]); // Add cardTexts and onGenerate as dependencies to ensure they are up-to-date

//   return (
//     <div className="container-fluid">
//       <p  style={{
//           cursor: "pointer",
//           fontSize: "14px",
//           color: "#007bff",
//           display: "flex",
//           alignItems: "center",
//         }}> <Undo2 size={15}/><span >(6)</span></p>
//       <div className="d-flex flex-column">
//         {cardTexts.map((cardText, index) => (
//           <div
//             key={cardText._id} // Use _id as key
//             onClick={() => {
//               setSelectedCard(index); // Click to select the card
//               onGenerate(cardText._id); // Trigger onGenerate with the card's _id
//             }}
//             className={`card ${selectedCard === index ? 'bg-primary text-white' : 'bg-light'}`}
//             style={{
//               cursor: 'pointer',
//               marginBottom: '5px',
//               padding: '5px 8px',
//               width: '150px',
//               height: '26px',  // Adjust card height to fit button
//               textAlign: 'center',
//               fontSize: '10px',
//               lineHeight: '1.2',
//               overflow: 'hidden',
//               display: 'flex',
//               flexDirection: 'column',  // Allow button to be placed under card text
//               justifyContent: 'center',
//               alignItems: 'center'
//             }}
//           >
//             ({index + 1}){cardText.text} 
//           </div>
//         ))}
//                 <button
//                 style={{borderRadius: '17px', backgroundColor: 'rgb(0, 123, 255)', color: 'white', marginBottom: '3px', fontSize: '13px', border: 'none', padding: '2px', transition: 'background-color 0.3s, transform 0.2s', cursor: 'pointer', transform: 'scale(1)'}}
//         >
//           (7) <AudioWaveform size={20}/>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Page3;


// button same line
import React, { useState, useEffect } from 'react';
import { AudioWaveform, Undo2 } from 'lucide-react';

const Page3 = ({ setCurrentAiPage, onGenerate }) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleBackClick = () => {
    console.log("Back button clicked");
    setCurrentAiPage("main");
  };

  const cardTexts = [
    { _id: "673dd99deece13c7de7105ef", text: "cat playing cricket" },
    { _id: "673eee07eece13c7de7106cf", text: "Dogs Playing with toys" },
    { _id: "673ed480eece13c7de71069d", text: "monkey playing" },
    { _id: "673dc73c826a5bbe390d54de", text: "cats playing football" },
  ];

  useEffect(() => {
    const handleKeyPress = (e) => {
      const cardIndex = parseInt(e.key) - 1;
      if (cardIndex >= 0 && cardIndex < cardTexts.length) {
        setSelectedCard(cardIndex);
        const audio_status = false
        onGenerate(cardTexts[cardIndex]._id, audio_status);
      } else if (e.key === "6") {
        handleBackClick();
      }
      else if (e.key === "7") {
        setCurrentAiPage(3);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [cardTexts, onGenerate]);

  return (
    <div className="container-fluid">
      <p 
        style={{
          cursor: "pointer",
          fontSize: "14px",
          color: "#007bff",
          display: "flex",
          alignItems: "center",
        }}
      > 
        <Undo2 size={15}/><span className="circle-button-remote">6</span>
      </p>
      <div className="d-flex flex-column">
        {cardTexts.map((cardText, index) => (
          <div
            key={cardText._id}
            onClick={() => {
              setSelectedCard(index);
              onGenerate(cardText._id);
            }}
            className={`card ${selectedCard === index ? 'bg-primary text-white' : 'bg-light'}`}
            style={{
              cursor: 'pointer',
              marginBottom: '5px',
              padding: '5px 8px',
              width: '150px',
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align content to start
              fontSize: '10px',
              position: 'relative', // Add relative positioning
            }}
          >
            {/* <span 
              style={{
                position: 'absolute', 
                left: '5px', 
                top: '50%', 
                transform: 'translateY(-50%)',
                fontWeight: 'bold'
              }}
            >
              ({index + 1})
            </span> */}
            <span className="circle-button-remote" style={{
                position: 'absolute', 
                left: '5px', 
                top: '50%', 
                transform: 'translateY(-50%)',
                fontWeight: 'bold'
              }}>{index + 1}</span>
            <span 
              style={{
                marginLeft: '25px', // Create space for the number
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {cardText.text}
            </span>
          </div>
        ))}
        <button
          style={{
            borderRadius: '17px', 
            backgroundColor: 'rgb(0, 123, 255)', 
            color: 'white', 
            marginBottom: '3px', 
            fontSize: '13px', 
            border: 'none', 
            padding: '2px', 
            transition: 'background-color 0.3s, transform 0.2s', 
            cursor: 'pointer', 
            transform: 'scale(1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '150px',
            height: '26px'
          }}
          onClick={() => setCurrentAiPage(3)}
        >
          <span className="circle-button-remote">7</span> <AudioWaveform size={20} style={{ marginLeft: '5px' }}/>
        </button>
      </div>
    </div>
  );
};

export default Page3;