import React from 'react';

function UserAvatar({ user, size = 'md' }) {
  const sizeClass = size === 'sm' ? 'user-avatar-small' : 'user-avatar-medium';

  return (
    <div 
      className={`user-avatar ${sizeClass}`} 
      // style={{ backgroundColor: user.color }}
    >
      {user.initial}
    </div>
  );
}

export default UserAvatar;
