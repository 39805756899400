import React from 'react';
import { useNavigate } from 'react-router-dom';

function Main() {
  const navigate = useNavigate();
  const userName = localStorage.getItem('userName'); // Retrieve the user's name

  const handleRoute = (route) => {
    if (route === 'current') {
      // navigate('/welcome');
      navigate('/current/welcome');
    } if ( route === 'programinfo'){
      navigate('/event/info');
    }
    else if (route === 'upcoming') {
      navigate('/event');
    }
  };

  return (
    <div>
      {/* <h1>Welcome, {userName}!</h1> */}
      <button onClick={() => handleRoute('current')}>Current</button>
      <button onClick={() => handleRoute('upcoming')}>Upcoming</button>
      {/* <button onClick={() => handleRoute('programinfo')}>channelinfo</button>
      <button onClick={() =>  navigate('/event/rooms')}>Rooms</button> */}
    </div>
  );
}

export default Main;
